.questionAndAnswer_container{
    display: flex;
    flex-direction: column;
    height: 100%;
}


.qa_question > textarea{
    border: none;
    width: 95%;
    right: 0;
    padding-top: 0.2rem;
    vertical-align: baseline;
    margin-left: 1rem;
    font-size: medium;
}


.qa_question{
  background-color: #fff;   
  border-radius: 10px;
  padding: 15px 5px;
  display: flex;
  align-items: center;
  border: 1px solid #d3d3d3;
}

.qa_user_icon{
    font-size: 1.8rem !important;
}

.qa_question > textarea::-webkit-input-placeholder {
    vertical-align:baseline;
 }

 .qa_question>textarea:focus {
    outline-width: 0;
}

.qa_anaynoms_options {
    display: flex;
    padding: 5px 20px 0px 20px;
}

.qa_options{
    display: flex;
    padding: 20px 20px 0px 20px;
}

.qa_question_type {
    margin-right: auto;
}
.qa_question_type > span {
    margin-left: 1rem;
    font-weight: 600;
    font-size: 1rem;
    cursor: pointer;
    
}

.qa_question_count{
    margin-left: 1rem;
    font-size: 0.9rem
}

.qa_topic_container{
    height: 100%;
    overflow-y: auto;
}
.qa_topic{
    background-color: #fff;
    border: 1px solid #d3d3d3;
    border-radius: 15px;
    margin-bottom: 1rem;
    margin-top: 0.5rem;
}

.qa_topic_top{
    display: flex;
    align-items: center;
}

.qa_topic_top_left{
    margin: 0px 15px;
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 2px 4px;
}

.qa_topic_top_left > span {
    font-weight:500;
    font-size: 14px;
}

.qa_user_time_container{
    display: flex;
    flex-direction: column;
    margin-top: 3px;

}
.qa_top_right{
    margin-left: auto;
    background-color: #d3d3d3;
    border-radius: 10px;
    padding: 2px 4px;
    display: flex;
    align-items: flex-end;
    margin-right: 6px;
    cursor: pointer;
}

.qa_userename > span {
    font-weight: bold;
    font-size: 14px;
}

.qa_time > span {
    font-weight: lighter;
    font-size: 12px;
    margin-top: 0px;
}

.qa_top_right_thumb{
    font-size: 1.2rem !important;
    cursor: pointer;
}

.dislike{
    color: #554f4f !important;
}


.like{
    color: #1029f7 !important;
}

.qa_top_right > span {
    font-size: 12px;
    padding-right: 3px;
}

.qa_topic_bottom{
    display: flex;
    justify-content: space-between;
    margin: 5px 15px;
}

.qa_topic_bottom > span{
    margin-left: 1rem;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    word-break: break-word;
}


.edit_qa_question > textarea{
    border: none;
    width: 95%;
    right: 0;
    padding-top: 0.2rem;
    vertical-align: baseline;
    margin-left: 1rem;
    font-size: medium;
}


.edit_qa_question{
  background-color: #fff;   
  border-radius: 10px;
  padding: 10px 5px;
  display: flex;
  align-items: center;
  border: 1px solid #d3d3d3;
  width: 80%;
}

.send_button{
    display: flex;
    flex-direction: column-reverse;
}

.edit_qa_question>textarea:focus {
    outline-width: 0;
}

.qa_reply_count{
    display: flex;
    margin: 2px 0px;
    cursor: pointer;
}

.qa_reply_count > span {
    font-size: 14px;
    margin: 0px 5px;
}

.qa_reply{
    margin-top: .5rem;
    border-top: 1px solid #d3d3d3;
    word-break: break-word;
}
