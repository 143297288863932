.notificationView-container{
    position: absolute;
    z-index: 9999;
    bottom: 20%;
    width: min-content;
    right: 5%;
    background-color: white;
}

.videoCall-cont {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff;
    width: 100vw;
    height: 100vh;
    z-index: 10001;
}

.closed{
    width: 50px;
    height: 40px;
    position: relative;
    top: -2px;
    float: right;
}