.conference_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.conference_header {
  border-bottom: 1px solid #d3d3d3;
  color: #000;
  padding: 5px 5px;
  font-weight: 600;
  letter-spacing: 1px;
}

.conference_subheader{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  cursor: pointer;

}

.conference_subheader > span{
  padding: 0px 4px;
  margin-right: auto;
}

.conference_header > span {
  margin-right: 1rem;
  cursor: pointer;
}

.agenda_container {
  width: 100%;
  height: 89%;
  overflow: auto;
}

.agenda_table {
  margin-bottom: 15px;
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
 
}

.agenda_head{
    width: 100%;
}

.agenda_head > tr{
     text-align: center;
    padding: 5px;
}

.agenda_body {
  width: 100%;
  height: 100%;
}

.agenda_desc{
  font-size: 12px;
}

.td ,.th {
  border: 1px solid #000;
  padding: 1.2rem;
   font-size: 0.8rem;

}


.agenda_content_watch, .agenda_content_rating, .agenda_time{
    white-space: nowrap;
    font-size: 0.8rem;
    text-align: center;
}


.disabled {
  pointer-events: none;
}