.confirmation_container{
    width: 100%;
    height: 100%;
    display: flex; 
    flex-direction: column;
    padding: 20px;
 }

 .confirmation_message > span{
     font-weight: 600;
 }

 .confirmation_button{
     display: flex;
     align-items: center;
     margin: auto;
 }

 .confirmation_button > button {
     background-color: black;
     color: white;
     padding: 10px 15px;
     border: none;
     border-radius: 10px;
     cursor: pointer;
 }
 