.feedBack_container{
   border: 1px solid #000;
   width: 100%;
   height: 97%;
   display: flex; 
   flex-direction: column;
}


.feedBack_header{
    background-color: #000;
    color: #fff;
    padding: 5px;
    font-weight: 600;
    letter-spacing: 1px;
}

.feedback_question{
    font-weight: 600;
}
.feedBack_question_container{
    padding: 30px;
    overflow-y: scroll;
    font-weight: 400;
}

.feedback_answer{
    margin-top: 10px;
    margin-left: 15px;
    margin-bottom: 10px;
    
}


.feedback_submit{
    margin: auto auto 5px auto;
   background-color: #000;
   color: #fff;
   padding: 10px 30px;
   border-radius: 50px;
   cursor: pointer;
}


.feedback_response{
    margin: auto auto 15px auto;
   color: #000;
   padding: 10px 30px;
   font-weight: bold;
}

.feedback_submit > span {
    font-weight: 600;
}


.feedback_option > input[type=text]{
    font-size: 16px;
    font-size: max(16px, 1em);
    font-family: inherit;
    padding: 0.25em 0.5em;
    /* background-color: #fff; */
    border: 2px solid #8b8a8b;
    border-radius: 4px;
  }

  .feedback_option > textarea{
    width: 50%;
    height: 100px;
  }

  .feedback_error_message{
      color: red;
      margin: auto;
  }
