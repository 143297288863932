.socialwall-wrapper{
    display: flex;
    width: 100%;
    /* margin-right: 10px; */
    height: 100%;
    align-items: center;
    justify-content: center;
}
.socialwall{
    display: flex;
    justify-items: center;
    align-items: center;
    width: 80%;
    /*  margin-right: 10px; */
    height: 100%;
    /* scroll-margin: 1px; */
    /* scrollbar-color: blue; */
}