.background{
 width:100%;
height:100%;
background:rgba(0,0,0,0.3);
position:fixed;
display:flex;
z-index: 99;
top: 0;
justify-content:center;
align-items:center;
}

.container{
align-items:center ;
display:grid;
grid-template-columns: auto auto;
position:relative;
border-radius:10px;
background-color: #fff  ;
color: black;
z-index: 10;
box-shadow: 0 5px 16px rgba(0,0,0,0.5);
width: 500px ;
height: 250px ;
}

.text{
    

text-align:center;
position:relative;
padding:20px;
height: auto;


}
.logo{
    cursor:pointer;
    
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 20px;
    padding: 2px;
    
    
}
.logo-container{
    border-radius: 50% ;
    right: 3px;
    top: 3px;
    position: absolute;
    width: 35px;
    height: 35px;
    background-color: black;
    align-items: center;
    text-align: center;
    display: inline-block;
    
    	
   
}
.logo-outer{
    border-radius: 50%;
    width: 41px;
    height: 41px;
    top: -12px;
    right:-12px;
    position:fixed;
    background-image: linear-gradient(to right, #7d43e0 , #352ee8, #5fd4c2);
    align-items: center;
    text-align: center;
}



@media screen and (max-width:574px){
    .container{
        width: 280px ;
        height: 200px ;
        overflow-x: auto;
        box-shadow: 0 5px 16px rgba(0,0,0,0.5);
        }
        
        .text{
        text-align:center;
        position:relative;
        padding:20px;
        height: auto;
        font-size:12px;
        }
}

@media screen and (max-width:736px){
    .container{
        width: 350px ;
        height: 250px ;
        overflow-x: auto;
        box-shadow: 0 5px 16px rgba(0,0,0,0.5);
        }
        
        .text{
        text-align:center;
        position:relative;
        padding:20px;
        height: auto;
        font-size:14px;
        }
}

@media screen and (max-width:786px){
    .container{
        width: 350px ;
        height: 250px ;
        overflow-x: auto;
        box-shadow: 0 5px 16px rgba(0,0,0,0.5);
        }
        
        .text{
        text-align:center;
        position:relative;
        padding:20px;
        height: auto;
        font-size:14px;
        }
}
@media screen and  (max-width:601px){
    .container{
        width: 280px ;
        height: 200px ;
        overflow-x: auto;
        box-shadow: 0 5px 16px rgba(0,0,0,0.5);
        }
        
        .text{
        text-align:center;
        position:relative;
        padding:20px;
        height: auto;
        font-size:12px;
        }

}
@media screen and (max-width:568px){
    .container{
        
        width: 250px ;
        height: 190px ;
        overflow-x: auto;
        box-shadow: 0 5px 16px rgba(0,0,0,0.5);
        }
        
        .text{
        text-align:center;
        position:relative;
        padding:20px;
        height: auto;
        font-size:12px;
        }
}

