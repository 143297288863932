.alertcommon-overall{
    width: 100%;
    height: auto;
    display: flex;
    grid-template-columns: auto;
    align-items: center;
    justify-content: center;
    

}
.alertcommon-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align:center;
    width: 90%;
    margin-top: 120px;
   
    
    

}
.alertcommon-text{
    font-size: 20px;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    width: 500px;

}