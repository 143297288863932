.edit_profile_container{
    border: 1px solid black;
    width: 99%;
    margin-bottom: 24px;
}

.edit_profile_container .edit_profile_heading{
    background-color: black;
    color: #ffff;
    padding: 6px 8px;
    font-size: 14px;
}

.edit_profile_container .nav-tabs{
    border-bottom: 1px solid black;
}

.edit_profile_container .nav-tabs .nav-link{
    color: black;
    min-width: 180px;
    text-align: center;
}

.edit_profile_container .nav-tabs .nav-link.active{
    color: rebeccapurple;
    border: none;
    border-bottom: 3px solid rebeccapurple;
}

.edit_profile_container  .border_line{
    width: 100%;
    height: 2px;
    background-color: black;
}

.edit_profile_container .edit_form{
    overflow: auto;
    height: calc(100vh - 300px);
}

.edit_profile_container .edit_form input{
    width: 90%;
}

.edit_profile_container .submit_profile_btn{
    background-color: blue;
    color: #ffff;
    width: 40%;
    border-radius: 4px;
    height: 40px;
    border: none;
    margin-bottom: 40px;
}

.edit_profile_container .validation_msg{
    margin: 0;
    margin-top: 4px !important;
    font-size: 12px !important;
    color: rgb(241, 52, 52);
}

.edit_profile_container .edit_profile_div{
    width: 70px;
    height: 70px;
    border-radius: 50px;
    background-color: rgb(211, 211, 211);
    position: relative;
}

.edit_profile_container .edit_profile_icon{
    position: absolute;
    bottom: 0;
    right: 4px;
    background-color: rgb(255, 255, 255);
    width: 25px;
    height: 25px;
    border-radius: 50%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}