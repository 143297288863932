@import url("https://fonts.googleapis.com/css2?family=Raleway&display=swap");
@import url("https://fonts.googleapis.com/css?family=Patrick+Hand+SC|Roboto&display=swap");

body {
    margin: 0;
    font-family: "Raleway";

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

@font-face {
    font-family: "Graphie";
    src: local("Graphie"),
        url(./assets/fonts/Graphie/Graphie-Book.otf) format("otf");
}

@font-face {
    font-family: "Grotesk";
    src: url(./assets/fonts/hk-grotesk/HKGrotesk-Regular.otf) format("otf");
}

.grotesk {
    font-family: "Grotesk";
}

.transformFix > div {
    transform: none;
}

.graphie {
    font-family: "Graphie";
}
