@import url("https://cdn.jsdelivr.net/npm/@xz/fonts@1/serve/hk-grotesk.min.css");


.app-simple.light {
  --app-background: #fff;
  --app-color: #585858;
  --app-avatar-background: #dddddd;
  --app-info-color: #9b9b9b;
  --app-info-border: #d7e0e9;
  --app-chat-background: #ffffff;
  --app-people-color: gray;
  --app-people-active-color: #4c37f7;
  width: 100%;
  background: var(--app-background);
  color: var(--app-color);
  display: flex;
  height: 100%;
  overflow: hidden;
  font-family: 'HK Grotesk', sans-serif;
  //ran
  margin-top: -1rem;
  margin-left: 3rem;
  

  .pn-msg-list--light {
    --msg-list__background: transparent;
    --msg--hover__background: #e5e8ec;
  }

  .pn-channel-list--light {
    --channel-list__background: #fff;
    --channel--active__background: linear-gradient(0.25turn,#4e4ddc,#4b49bb,#7473b1,#E0E0E0 );
    // --channel--hover__background: #4c37f7;
    --channel--hover__background: linear-gradient(0.25turn,#7070ca,#7a79b3,#8584a8,#E0E0E0 );
    --channel__name__color: #444444;
    --channel--active__name__color: #ffffff;
    //ran
    margin-left: 0rem;
    z-index: 1;
    margin-top: 0rem;
  }
}
.pn-channel{
  border-top: 1px solid rgb(209, 209, 209) ;
}
.right-end-wrapper{
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: 100%;
  width: 20%;
}
.right-end-top-wrapper{
  display: flex;
  height: 70%;
  flex-direction: column;
  width: 100%;
  

}
.right-end-bottom-wrapper{
  display: flex;
  height: 30%;
  flex-direction: column;
  width: 100%;
  
  
}
.right-end-top-title{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;

  span{
    font-size: 12px;
    padding-top: 10px;
    font-weight: bold;
  }

}
.right-end-top-content-overall{
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%; 
  overflow-y: auto;
}
.right-end-top-content{
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  height: auto;
 
}

.right-end-top-common{
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: 1px solid rgb(167, 167, 167);
  padding:10px 0;
  height: 100%;
  // top: 3rem;
  top: 0;
  position: relative;
  
}
.right-end-top-common-image-container{
  display: flex;
 height: 100%;
 margin-right: 5px;
  // padding: 0px;
  // align-items: center;
  justify-content: flex-start;
  
}
.right-end-top-common-image{
  display: flex;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  padding: 5px;
  align-items: center;
  justify-content: center;
  background-color: #4c37f7;
}
.right-end-profile-image{
  width:40px !important;
  height:40px !important;
  font-size: 15px !important;
  
}
.right-end-profile-image span{
  font-size: 15px !important;
  
}
.right-end-top-common-image-profile{
  display: flex;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  padding: 5px;
  align-items: center;
  justify-content: center;
 background: none;
}
.right-end-top-common-info{
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
 

}
.right-end-top-common-info-name{
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  width: inherit;
  margin-bottom: 3px;

  span{
    font-size: 12px;
    
    color: #000000;
    font-weight: bold;
  }
}
.right-end-top-common-info-role{
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  margin-bottom: 3px;

  span{
    font-size: 10px;
    font-weight: 500;
    color: #000000;
  }
}

.actions-common-holder{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-right: 5px;
}

.actions-common-button-outer{
  display: flex;
  align-items: center;
  justify-content: center;
  width:25px;
  height:25px;
  padding:2px;
  background: linear-gradient(90deg,#f66464,#0632ff 60%,#4eb589);
  border-radius: 5px;
}
.actions-common-button-inner{
  display: flex;
  align-items: center;
  justify-content: center;
  width:100%;
  height:100%;
  background-color: black;
  border-radius: 5px;
}
.chat-video-icon{
  width: 15px !important;
  height:15px !important;
  color: white;
}

.right-end-top-common-info-actions{
  display: flex;
  flex-direction: row;
  width: 100%;
}

.right-end-bottom-title{
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100%;
  height: 20%;

  span{
    font-size: 12px;
    padding-top: 10px;
    font-weight: bold;
  }

}
.right-end-bottom-content{
  display: flex;
  flex-direction: column;
  
  width: 100%;
  height: 80%;
}




.bottom-portion{
  display: flex;
  flex-direction: row;
  
  width: 100%;
  height: 25%;
  justify-content: flex-end;
  align-items: center;
}

.bottom-add{
  display: flex;
  border-radius: 50%;
  margin-right: 10px;
  width: 30px;
  height: 30px;
  background-color: #4c37f7;
  align-items: center;
  justify-content: center;
  box-shadow:  0px 3px 4px 2px rgb(155, 155, 155);
}
.add-icon{
  color: whitesmoke;
  cursor: pointer;
}

.app-simple.dark {
  --app-background: #292b2f;
  --app-color: #ffffff;
  --app-avatar-background: #414348;
  --app-info-color: #9b9b9b;
  --app-info-border: #414348;
  --app-chat-background: #ffffff;
  --app-people-color: gray;
  --app-people-active-color: #de2440;

 

  .pn-msg-list--dark {
    --msg-list__background: transparent;
  }

  .pn-channel-list--dark {
    --channel-list__background: #fff;
    --channel--active__background: #2f3136;
    --channel--hover__background: #de2440;
    --channel__name__color: #9b9b9b;
    --channel--active__name__color: #9b9b9b;
  }
}


.app-simple {
  background: var(--app-background);
  color: var(--app-color);
  display: flex;
  height: 100%;
  overflow: hidden;


  .pn-msg-input {
    --msg-input__send__borderRadius: 50%;
    --msg-input__send__border: 1px solid rgb(190, 190, 190);
    --msg-input__send__padding: 0px 0px;
    --msg-input__textarea__borderRadius: 30px;
    --msg-input__textarea__padding: 0px 0px;
    --msg-input__textarea__height: 20px;
    --msg-input__textarea__boxShadow: 0px 1px 4px 1px rgb(155, 155, 155);
    --msg-input__background: transparent;
    --msg-input__send__background: #4c37f7;
    --msg-input__send__minWidth: 35px;
    



  }
  .pn-msg-input__wrapper{
    display: flex;
    height: 40px;
    width: 100%;
    //ran
    // margin-left: 6rem;
    margin-left: 4px;
    z-index: 1;
    position: relative;
    top: 1rem
  }
  .pn-msg-input__textarea{
    margin: 10px ;
  }

  .pn-channel-list {
    --channel__description__display: none;
    --channel__name__fontSize: 13px;
    --channel__name__fontWeight: 400;
    --channel__padding: 8px 24px;
    
  }
  .pn-channel-list::-webkit-scrollbar {
    display: none;
  }
  

  .pn-member-list {
    --member__padding: 9px 0;
    --member__name__margin: 8px 0 0;
    --member__title__display: none;
    --member--hover__background: transparent;
  }

  .pn-msg-list {
    --member__padding: 9px 0;
    --member--hover__background: transparent;
  }

  .toggle {
    margin-top: 50px;
    position: relative;

    span {
      font-size: 12px;
      font-weight: bold;
      margin-left: 24px;
    }

    button {
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
    }
  }
  .chat-modal-title{
    font-weight: bolder;
    padding: 15px;
    background-color: none;
    color: black;

  }
  .channels {
    background: var(--app-background);
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 250px;
   

    // h4 {
    //   margin: 34px 24px 12px;
    // }
  }

  // .user {
  //   display: flex;
    
  //   padding: 46px 24px 20px;

  //   h4 {
  //     flex-grow: 1;
  //     margin: 8px ;
  //     font-size: 20px;
  //   }

  //   img {
  //     background: var(--app-avatar-background);
  //     display: inline-block;
  //     align-items: center;
  //     margin: 0 24px 0 0;
  //     width: 36px;
  //     height: 36px;
  //     border-radius: 36px;
  //   }
  // }
   .info-overall{
     display: flex;
     flex-grow: 1;
     justify-content: left;
     margin-top: -3rem;

     
     
   }
  .info {
    
    //  margin: 22px 20px ;
    margin: 20px;
    //  width: 100%;
  // ran
  width: 70%;
     height: 50%;
     border-radius: 5px;
    //  border: 2px solid rgb(205, 205, 205);
      box-shadow:  0px 1px 4px 1px rgb(155, 155, 155);
     background-color: #ffffff;
    justify-content: center;
     
    //ran
    position: relative;
    top: 2rem;
    left: 26%;
    z-index: 1;
   

    h4 {
      font-size: 20px;
      font-weight: bold;
      color:#292b2f;
      background-color: transparent;
      padding: 10px;
    }

    small {
      color: var(--app-info-color);
      margin: 5px 10px ;
      font-size: 10px;
    }

    hr {
      background-color: var(--app-info-border);
      border: 0;
      height: 1px;
      margin: 18px 0 0;
    }
  }

  .chat {
    background: var(--app-chat-background);
    // border-radius: 25px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    // margin: 20px 20px 20px 0;
    padding-bottom: 10px;
    position: relative;
  }

  .members {
    background: var(--app-background);
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    transform: translateX(310px);
    transition: 0.3s ease-in-out;
    width: 0;
    

    &.shown {
      transform: none;
      width: 310px;
    }

    h4 {
      margin-top: 54px;
    }

    .close {
      margin-right: 24px;
    }
  }

  .close {
    cursor: pointer;
    display: none;
    float: right;
  }

  .hamburger {
    display: none;
    float: left;
    font-size: 24px;
    margin-right: 15px;
  }

  .people {
    align-items: center;
    color: var(--app-people-color);
    cursor: pointer;
    display: flex;
    font-size: 13px;
    position: absolute;
    right: 34px;
    top: 34px;
    z-index: 1;
   

    &.active {
      color: var(--app-people-active-color);
    }

    span {
      margin: 2px 8px 0 0;
    }
  }

  // @media (max-width: 950px) {
  //   .channels,
  //   .members.shown {
  //     width: 200px;
  //   }
  // }

  @media (max-width: 700px) {
    .channels {
      height: 100%;
      position: absolute;
      z-index: 10;
      transition: 0.3s ease-in-out;
      width: 100%;

      &:not(.shown) {
        transform: translateX(-100%);
      }
    }

    .user {
      padding-top: 24px;
    }

    .close {
      display: inline-block;
    }

    .chat {
      margin: 5px;
    }

    .hamburger {
      display: inline;
    }

    .members.shown {
      height: 100%;
      padding: 24px;
      position: absolute;
      z-index: 10;
      width: 100%;

      h4 {
        margin-top: 0;
      }

      h4 span {
        margin-right: 48px;
      }
    }
  }
}

@media (min-width: 2561px){
  .right-end-top-common-info-name span {
    font-size: 15px;
  }

  .right-end-top-common-info-role span {
    font-size: 12px;
  }

  .right-end-wrapper {

    width: 15%;
  }
  .right-end-top-title span{
    font-size: 20px;
  }

}

@media (max-width: 2560px){
  .right-end-top-common-info-name span {
    font-size: 14px;
  }

  .right-end-top-common-info-role span {
    font-size: 12px;
  }

  .right-end-wrapper {

    // width: 20%;
    width: 50%;
  }

  .right-end-top-title span{
    font-size: 20px;
  }
}
@media (max-width: 1440px){
  .right-end-top-common-info-name span {
    font-size: 12px;
  }

  .right-end-top-common-info-role span {
    font-size: 10px;
  }

  .right-end-wrapper {

    width: 25%;
  }

  .right-end-top-title span{
    font-size: 15px;
  }
  
 .actions-common-button-outer{
  width: 25px;
  height:25px;
  padding: 2px;
  border-radius: 3px;
}
.actions-common-button-inner{
  border-radius: 3px;
}
.chat-video-icon{
  width: 12px !important;
  height: 12px !important;
}
  
}
@media (max-width: 1024px){
  .right-end-top-common-info-name span {
    font-size: 10px;
  }

  .right-end-top-common-info-role span {
    font-size: 8px;
  }

  .right-end-wrapper {

    width: 30%;
  }

  .right-end-top-title span{
    font-size: 12px;
  }
  .app-simple .channels {
    width: 120px;
   
 }
 .actions-common-button-outer{
  width: 20px;
  height:20px;
  padding: 2px;
  border-radius: 2px;
}
.actions-common-button-inner{
  border-radius: 2px;
}
.chat-video-icon{
  width: 10px !important;
  height: 10px !important;
}

.right-end-profile-image{
  width: 30px !important;
  height:30px !important;
    

}

.right-end-profile-image span{
  font-size: 10px !important;

}
  
}
@media (max-width: 768px){
  .right-end-top-common-info-name span {
    font-size: 13px;
  }
  .right-end-top-common-info-role span {
    font-size: 4px;
  }
  .right-end-wrapper {
    width: 30%;
   
  }
  .right-end-top-title span{
    font-size: 8px;
  }
  .right-end-profile-image{
    width: 30px !important;
    height:30px !important;
  }
  .right-end-profile-image span{
    font-size: 8px !important;
  }

  .actions-common-button-outer{
    width: 20px;
    height:20px;
    padding: 2px;
    border-radius: 2px;
  }
  .actions-common-button-inner{
    border-radius: 2px;
  }
  .chat-video-icon{
    width: 10px !important;
    height: 10px !important;
  }

  .app-simple .channels {
     width: 120px;
     /* new*/
     margin-left: -6rem;
    
  }
  
  .chat-modal-title {
    font-size: 15px;
  }

  
  
}
@media (max-width: 425px){
  
}
@media (max-width: 320px){
  
  
}


