.speaker-container{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.card-container{
    width: 200px;
    height: 285px;
    background: #000;
    border-radius: 6px;
    overflow: hidden;
    margin: 10px 10px;
}
    .upper-container{
    height: 40%;
    background: #d3d3d3;
    }
    .image-container{
    background: #000;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    padding: 5px;
    transform: translate(50%,30%);
    }
    .image-container img{
    width: 90px;
    height: 90px;
    border-radius: 50%;
    }
    .lower-container{
    height: 100%;
    background: #000;
    padding-top: 10px;
    text-align: center;
    }
    .lower-container span{
    box-sizing: border-box;
    line-height: 0.5;
    font-weight: 600;
    color: #fff;
    font-size: 0.8rem;
    overflow-wrap: break-word;
    margin-bottom: 1rem;
    }
  
    .lower-container .btn{
    padding: 10px 15px;
    background: #777474;
    border: none;
    color: white;
    border-radius: 10px;
    font-size: 12px;
    text-decoration: none;
    font-weight: bold;
    transition: all .3s ease-in;
    cursor: pointer;
    }

    .speaker_company{
        font-weight: 400 !important;
        margin-top: .5rem;
    }