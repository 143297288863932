.widget-box {
    background: #f7f7f7;
    border-radius: 5px;
    border: 1px solid #ddd;
    text-align: center;
    margin-bottom: 15px;
    margin-top: 60px;
}
    .widget-box .box-img {
        background: white;
        padding: 10px;
        text-align: center;
        border-bottom: 1px solid #ddd;
        display: flex;
        justify-content: center;
    }
.categoryproduct{    padding-left: 7%;
    padding-right: 7%;}
.widget-box h3 {
    margin-top: 34px;
    font-weight: bold;
    font-size: 20px;
    text-transform: capitalize;}
    .widget-box .box-img img{margin-right: 10px;width:32px;}
    .widget-box .box-img:last-of-type {
        border: none;
        color: #003bfe;
      }
    .widget-box .box-img.no-left{border:none !important;justify-content: left;text-align: left;}
    .widget-box .box-img.mno-left{justify-content: left;text-align: left;}
  .widget-box .box-img p  {margin-bottom: 0px;
        margin-top: 5px;}
    .widget-box p {    font-weight: 400;
        text-transform: capitalize;
        font-size: 15px;
        line-height: 23px;
        margin-bottom: 9px;}

    .widget-box .box-img:last-of-type {
        border: none;
    }

    .widget-box .count {
    position: absolute;
    padding: 20px;
    background: #003bfe;
    display: inline-block;
    top: 25px;
    border-radius: 50px;
    margin-left: -36px;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
}