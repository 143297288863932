.videoCallWindow-container{
    /* display: flex;
    width: 100vw;
    height: 90vh; */
    display: flex;
    width: 80%;
    height: 90vh;
    margin-top: 3rem;
    margin-left: 14%;
}

.video-call-notifyclose{
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    padding: 44px 90px 0;
    position: absolute;
    cursor: pointer;
    right: 5%;
   
}

.video-call-notifyclose > span{
    background-color:  #4724da;
    color: white;
    padding: 7px 15px;
    border-radius: 4px;
    font-weight: 600;
}

.upload-container {
    width: 500px;
    height: 500px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }