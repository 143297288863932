@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,500;0,600;0,800;1,400;1,500&display=swap');

body {
    padding: 0px;
    margin: 0px;
    font-family: 'Montserrat', sans-serif;
}

.closed{
    width: 50px;
    position: absolute;
    right: 7rem;
    cursor: auto;
    z-index: 1;
}
.networking{
    width: 90%;
    position: initial;
    margin-left: 8rem;
}

.position{
    position: relative;
    margin-left: 7rem;
    width: 82%;
    margin-top: 3rem;
  
}



@media only screen and (max-width:999px) {
    .position{
        margin-left: 1rem;
        /* width: 70%; */
        margin-top: -3rem;
        width: 100%;
    }
    
}
@media only screen and (max-width:760px) {
    .position{
        width: 70%;
        margin-left: 5rem;
    }
}
.table-box {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: #ffff;
    background-repeat: no-repeat;
    background-attachment: fixed;
    padding: 50px 60px;
    background-position: center;
    overflow: auto;
    margin-left: 5rem;
    background-size: cover;
}

.table-common-box {
    margin-bottom: 100px;
    width: 100%;
    height: 100%;
    display: inline-block;
    margin-left: 5%;
}

.box-violet {
    background: #0739ff;
    padding: 50px 50px 30px 30px;
    /* width: 47%; */
    width: 40%;
    float: left;
    border-radius: 20px;
    margin: 15px;
    box-sizing: border-box;
}

.box-content {
    width: 50%;
    float: left;
    padding: 0px;
}

.box-content h4 {
    margin: 0px 0px 10px 0px;
    font-weight: 600;
    font-size: 20px;
    text-transform: capitalize;
    color: #fff;
}

.box-content p {
    margin: 0px 30px 10px 0px;
    font-weight: 600;
    font-size: 15px;
    line-height: 1.7;
    color: #fff;
}

.join-us {
    padding: 10px 30px;
    border-radius: 20px;
    border: none;
    margin: 14px 0px;
    font-size: 15px;
    font-weight: 600;
    background: #ff4500;
    color: #fff;
    text-transform: uppercase;
}

.box-content p.seats {
    margin: 0px 0px 10px 0px;
    font-weight: 600;
    font-size: 15px;
    color: #fff;
}

.table-round {
    width: 200px;
    height: 200px;
    background: #fc5529;
    border: 2px solid #fc5529;
    float: right;
    border-radius: 50%;
    position: relative;
    text-align: center;
    top: 20px;
}


.table-round .chair {
    position: absolute;
    width: 45px;
    height: 45px;
    /* border-radius: 13%/100px 100px 0 0; */
    /* perspective: 150px; */
    background-image: url(../../../assets/armchair.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.table-round .chair:nth-child(1) {
    transform: rotate(311deg);
    top: 0px;
    left: -13px;
}

.table-round .chair:nth-child(2) {
    top: 75px;
    transform: rotate(273deg);
    right: 200px;
}

.table-round .chair:nth-child(3) {
    top: 157px;
    transform: rotate(228deg);
    left: -10px;
}

.table-round .chair:nth-child(4) {
    left: 80px;
    top: -42px;
    transform: rotate(1deg);
}

.table-round .chair:nth-child(5) {
    right: -8px;
    top: -6px;
    transform: rotate(46deg);
}

.table-round .chair:nth-child(6) {
    top: 75px;
    right: -40px;
    transform: rotate(90deg);
}

.table-round .chair:nth-child(7) {
    top: 160px;
    transform: rotate(133deg);
    right: -8px;
}

.table-round .chair:nth-child(8) {
    top: 197px;
    left: 80px;
    transform: rotate(180deg);
}

.client-logo {
    /* width: 65px;
    position: absolute;
    height: 65px;
    background: white;
    left: 30%;
    top: 53px;
    padding: 10px;
    border-radius: 10px;
    z-index: 9999; */

    width: 65px;
    position: absolute;
    height: 65px;
    background: white;
    left: 35%;
    top: 65px;
    padding: 10px;
    border-radius: 10px;
    z-index: 9999;
}

.closebtn{
    position: relative;
    display: flex;
    float: right;
    margin-right: 3rem;
}

.client-logo img {
    width: 100%;
}

.red:nth-child(1) .circle {
    background: #b70001;
}

.red:nth-child(2) .circle {
    background: #cd4300;
}

.red:nth-child(3) .circle {
    background: #688443;
}

.red:nth-child(4) 
+.circle {
    background: #00948b;
}

.red:nth-child(5) .circle {
    background: #d03955;
}

.red:nth-child(6) .circle {
    background: #cd4300;
}

red:nth-child(7) .circle {
    background: #7c4770;
}

.red:nth-child(8) .circle {
    background: #e58916;
}

.chair.red:nth-child(1),
.chair.red:nth-child(2),
.chair.red:nth-child(3),
.chair.red:nth-child(4),
.chair.red:nth-child(5),
.chair.red:nth-child(6),
.chair.red:nth-child(7),
.chair.red:nth-child(8) {
    background-image: url(../../../assets/armchairwhite.png);
}

.circle {
    /* border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 5px;
    position: relative;
    top: 24px;
    font-size: 15px;
    border: 3px solid #fff;
    line-height: 27px;
    color: #fff; */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 5px;
    position: relative;
    top: 19px;
    left: 3px;
    font-size: 15px;
    border: 3px solid #fff;
    line-height: 27px;
    color: #fff;
}

.container {
    width: 1180px;
    margin: 0 auto;
}

@media screen and (max-width: 767px) {
    .container {
        width: 100%;
        padding: 0px 10px;
        box-sizing: border-box;
    }

    .box-content p {
        margin: 0px 0px 10px 0px;
    }

    .box-violet {
        width: 80%;
        margin: 0px;
        margin-bottom: 30px;
        padding: 30px 30px 120px 30px;
        margin-left: -4%;
    }

    .box-content {
        width: 100%;
    }

    .table-round {
        top: 35px;
        left: 45px;
        float: left;
    }

    .join-us {
        margin-top: 0px;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .container {
        width: 100%;
        padding: 0px 20px;
        box-sizing: border-box;
    }

    .box-content p {
        margin: 0px 0px 10px 0px;
    }

    .box-violet {
        width: 80%;
        margin: 0px;
        margin-bottom: 30px;
        padding: 60px;
        /* margin-left: -4%; */
    }
}


@media only screen and (max-width: 2000px) {
    .frame{
        width: 75%;
        margin-left: 11rem;
        height: 100%;
        margin-top: 3rem;
    }  
}


@media only screen and (max-width:1500px){
    .closed{
        right: 2rem;
        z-index: 1;
    }
}






@media only screen and (min-device-width: 1265px) and (max-device-width: 1319px) {
    .box-violet{
        width:42%
    }
}
@media only screen and (min-device-width: 1025px) and (max-device-width: 1264) {
    .box-violet{
        width:70% !important;
    }
}

@media only screen and (max-width:1264px){
.box-violet{
    width:70%;
}
}

@media only screen and (max-width:875px){
    .table-round{
        width: 150px;
        height: 150px;
    }

    .client-logo{
        width: 50px;
        height: 50px;
        top: 48px;
    }

    .table-round .chair:nth-child(2){
        right: 130px;
    }

}
    
@media only screen and (max-width:766px){
    .table-common-box{
        width: 80%;
    }
    .box-violet{
        width: 87%;
        padding: 10px;
    }

    .box-content{
        width: 50%;
    }


}

@media only screen and (max-width:695px){
    .table-common-box{
        width: 100%;
    }
    .box-violet{
        width: 90%;
        margin-left: -12%;
    }

    .box-content{
        width: 50%;
    }


}

@media only screen and (max-width: 1000px) {
    .frame{
        margin-left: 6rem;
    }  
}
