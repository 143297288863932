@import url("https://cdn.jsdelivr.net/npm/@xz/fonts@1/serve/hk-grotesk.min.css");

.text-color{
    color: #727381;
}


.loader{
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 99;
}

.spinner{
    position: absolute;
    top: 50vh;
    left: 50vw;
}


@media only screen and (max-width:6144px){
    .mar{
        margin-left: 1.5rem;
    }
    .mar-l{
        margin-left: 0rem;
    }
    .pos{
        margin-left: 2rem;
        margin-top: 1rem;
        padding: 1px;
    }
    .userList-container{
        display: flex;
        width: 100%;
        height: 100%;
        grid-template-columns: 100%;
        align-items: center;
        justify-content: center;
        background-color: #edf2f6;
        font-family: 'HK Grotesk', sans-serif;
        font-weight: 600;
    }
    .userList-body-wrapper{
        display: flex;
        width: 80%;
        /* height: 90%; */
        align-items: center;
        justify-content: center;
        height: 80% !important;
       
      
    }
    .userList-body{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
    }
    .userList-users-container {
        display: flex;
        flex: 1;
        flex-direction: column;
        width: 100%;
        height: 80%;
        align-items: center;
        justify-content: center;
        overflow-y: auto;
    }
    .userList-users-container-inner{
        display: flex;
        width: 100%;
        flex-direction: column;
        overflow-y: auto;
    }
    .userList-users{
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-direction: row;
        margin: 0.5rem 0px;
        padding: 0.3rem;
        border-radius: 5px;
        background-color: white;
        

    }
    .userList-users-overall{
        display: flex;
        flex-direction: column;
        
        width: 100%;
    }
    .userList-users-header{
        display: flex;
        justify-content: space-between;

        flex-direction: row;
        width: 100%;
        border-radius: 5px;
        background-color: #0739ff;


    }
    .userList-users-img-header{
        display: flex;
        flex-direction: row;
        min-width: 60px;
        justify-content: left;
        font-size: 25px;  
        color: #ffffff;

    }
    .userList-users-blank-header{
        display: flex;
        flex-direction: row;
        min-width: 250px;
        justify-content: left;
        font-size: 25px;  
        color: #ffffff;
    }

    .userList-users-name-header{
        display: flex;
        flex-direction: row;
        min-width: 150px;
        justify-content: left;
        font-size: 20px;  
        color: #ffffff;
    }
    .userList-users-desc-header{
        display: flex;
        flex-direction: row;
        min-width: 150px;
        justify-content: left;
        font-size: 20px;   
        color: #ffffff;
        

    }
    .userList-users-company-header{
        display: flex;
        flex-direction: row;
        min-width: 150px;
        justify-content: left;
        font-size: 20px;   
        color: #ffffff;
        

    }
    .userList-users-interests-header{
        display: flex;
        flex-direction: row;
        min-width: 150px;
        justify-content: left;
        font-size: 20px;  
        color: #ffffff;
    }
    .userList-users-pic{
        display: flex;
        flex-direction: row;
        min-width: 60px;
        align-items: center;
        justify-content: center;
        
    }
    .userList-users-name{
        display: flex;
        flex-direction: row;
        min-width: 150px;
        align-items: center;
        justify-content: left;
        font-size: 18px;
    }
    .userList-users-desc{

        display: flex;
        flex-direction: row;
        min-width: 150px;
        align-items: center;
        justify-content: left;
        font-size: 18px;
    }
    .userList-users-company{
        
        display: flex;
        flex-direction: row;
        min-width: 150px;
        align-items: center;
        justify-content: left;
        font-size: 18px;
    }
    .userList-users-interests{
        display: flex;
        flex-direction: row;
        min-width: 150px;
        align-items: center;
        justify-content: left;
        font-size: 18px;

    }
    .userList-pagination{
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;  
    }
    .userList-users-actions{
        display: flex;
        flex-direction: row ;
        justify-content: space-evenly;
        min-width: 250px;
        text-align: center;
    }
    .img-icons{
        /* width: 40px; */
        /* height: 40px; */
        width: 45px;
        height: 45px;
        cursor:pointer;
    }
    .img-profile{
        width: 30px;
        height: 30px;
    }
    .userList-total-records > span {
        color: #727381;
        font-size: 12px;
    }
    .userList-total-records{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        justify-content: left;
    }
    
    
    .userList-pages{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        border: 1px solid #edf2f6;
    }
    .userList-page{
        padding: 5px 15px;
        background-color: #fff;
        color: #727381;
        cursor: pointer;
         margin-left: .5rem; 
        border-radius: 3px;
    }
    
    .userList-page.active {
        background-color: #0f26e0;
        color: #fff;
    }
    .userList-users-actions-single{
        color:#727381; 
        font-size:12px ; 
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: left;
        padding-right: 5px;
    }

}

@media only screen and ( max-width:2560px){
    .userList-container{
        display: flex;
        width: 100%;
        height: 100%;
        grid-template-columns: 100%;
        align-items: center;
        justify-content: center;
        background-color: #edf2f6;
        font-family: 'HK Grotesk', sans-serif;
        font-weight: 600;
    }
    .userList-body-wrapper{
        display: flex;
        width: 80%;
        /* height: 90%; */
        align-items: center;
        justify-content: center;
        height: 80% !important;
       
      
    }
    .userList-body{
        display: flex;
        flex-direction: column;
        width: 100%;
        height:100%;
        align-items: center;
        justify-content: center;
    }
    .userList-users-container {
        display: flex;
        flex: 1;
        flex-direction: column;
        width: 100%;
        height: 500px;
        align-items: center;
        justify-content: center;
        overflow-y: auto;
    }
    .userList-users-container-inner{
        display: flex;
        width: 100%;
        flex-direction: column;
        overflow-y: auto;
    }
    .userList-users{
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-direction: row;
        margin: 0.5rem 0px;
        padding: 0.3rem;
        border-radius: 5px;
        background-color: white;
        

    }
    .userList-users-overall{
        display: flex;
        flex-direction: column;
        
        width: 100%;
    }
    .userList-users-header{
        display: flex;
        justify-content: space-between;

        flex-direction: row;
        width: 100%;
        border-radius: 5px;
        background-color: #0739ff;


    }
    .userList-users-img-header{
        display: flex;
        flex-direction: row;
        min-width: 60px;
        justify-content: left;
        font-size: 25px;  
        color: #ffffff;

    }
    .userList-users-blank-header{
        display: flex;
        flex-direction: row;
        min-width: 250px;
        justify-content: left;
        font-size: 25px;  
        color: #ffffff;
    }

    .userList-users-name-header{
        display: flex;
        flex-direction: row;
        min-width: 150px;
        justify-content: left;
        font-size: 20px;  
        color: #ffffff;
    }
    .userList-users-desc-header{
        display: flex;
        flex-direction: row;
        min-width: 150px;
        justify-content: left;
        font-size: 20px;   
        color: #ffffff;
        

    }
    .userList-users-company-header{
        display: flex;
        flex-direction: row;
        min-width: 150px;
        justify-content: left;
        font-size: 20px;   
        color: #ffffff;
        

    }
    .userList-users-interests-header{
        display: flex;
        flex-direction: row;
        min-width: 150px;
        justify-content: left;
        font-size: 20px;  
        color: #ffffff;
    }
    .userList-users-pic{
        display: flex;
        flex-direction: row;
        min-width: 60px;
        align-items: center;
        justify-content: center;
        
    }
    .userList-users-name{
        display: flex;
        flex-direction: row;
        min-width: 150px;
        align-items: center;
        justify-content: left;
        font-size: 18px;
    }
    .userList-users-desc{

        display: flex;
        flex-direction: row;
        min-width: 150px;
        align-items: center;
        justify-content: left;
        font-size: 18px;
    }
    .userList-users-company{
        
        display: flex;
        flex-direction: row;
        min-width: 150px;
        align-items: center;
        justify-content: left;
        font-size: 18px;
    }
    .userList-users-interests{
        display: flex;
        flex-direction: row;
        min-width: 150px;
        align-items: center;
        justify-content: left;
        font-size: 18px;

    }
    .userList-pagination{
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;  
    }
    .userList-users-actions{
        display: flex;
        flex-direction: row ;
        justify-content: space-evenly;
        min-width: 250px;
        text-align: center;
    }
    .img-icons{
        /* width: 40px;
        height: 40px; */
        width: 45px;
        cursor:pointer;
    }
    .img-profile{
        width: 30px;
        height: 30px;
    }
    .userList-total-records > span {
        color: #727381;
        font-size: 12px;
    }
    .userList-total-records{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        justify-content: left;
    }
    
    
    .userList-pages{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        border: 1px solid #edf2f6;
    }
    .userList-page{
        padding: 5px 15px;
        background-color: #fff;
        color: #727381;
        cursor: pointer;
         margin-left: .5rem; 
        border-radius: 3px;
    }
    
    .userList-page.active {
        background-color: #0f26e0;
        color: #fff;
    }
    .userList-users-actions-single{
        color:#727381; 
        font-size:12px ; 
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: left;
        padding-right: 5px;
    }
    

}
@media only screen and ( max-width:1440px){
    .pos{
        margin-left: 0rem;
    
    }
    .userList-container{
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        background-color: #edf2f6;
        font-family: 'HK Grotesk', sans-serif;
        font-weight: 600;
        
    }
    .userList-body-wrapper{
        display: flex;
        width: 80%;
        height:80%;
        align-items: center;
        justify-content: center;
       
      
    }
    .userList-body{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;

    }
    .userList-users-container{
        display: flex;
        flex: 1;
        flex-direction: column;
        width: 100%;
        height: 80%;
        border-radius: 5px;
        align-items: center;
        justify-content: space-between;
        

    }
    .userList-users-container-inner{
        display: flex;
        width: 100%;
        flex-direction: column;
        overflow-y: auto;
    }
    .userList-users{
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-direction: row;
        margin: 0.5rem 0px;
        background-color: white;
        border-radius: 5px;
        
    }
    .userList-users-overall{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        width: 100%;
    }
    .userList-users-header{
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        width: 100%;
        border-radius: 5px;
        background-color: #0739ff;
        margin: 15px;

    }
    .userList-users-img-header{
        display: flex;
        flex-direction: row;
        min-width: 60px;
        justify-content: left;
        font-size: 18px;  
        color: #ffffff;

    }
    .userList-users-blank-header{
        display: flex;
        flex-direction: row;
        min-width: 200px;
        justify-content: left;
        font-size: 18px;  
        color: #ffffff;

    }

    .userList-users-name-header{
        display: flex;
        flex-direction: row;
        min-width: 150px;
        justify-content: left;
        font-size: 18px;  
        color: #ffffff;

        
    }
    .userList-users-desc-header{
        display: flex;
        flex-direction: row;
        min-width: 150px;
        justify-content: left;
        font-size: 18px;   
        color: #ffffff;
        

    }
    .userList-users-company-header{
        display: flex;
        flex-direction: row;
        min-width: 150px;
        justify-content: left;
        font-size: 18px;   
        color: #ffffff;
        

    }
    .userList-users-interests-header{
        display: flex;
        flex-direction: row;
        min-width: 150px;
        justify-content: left;
        font-size: 18px;  
        color: #ffffff;
        
    }
    .userList-users-pic{
        display: flex;
        flex-direction: row;
        min-width: 60px;
        align-items: center;
        justify-content: center;
        
    }
    .userList-users-name{
        display: flex;
        flex-direction: row;
        min-width: 150px;
        align-items: center;
        justify-content: left;
        font-size: 15px;

    }
    .userList-users-desc{

        display: flex;
        flex-direction: row;
        min-width: 150px;
        align-items: center;
        justify-content: left;
        font-size: 15px;
    }
    .userList-users-company{
        
        display: flex;
        flex-direction: row;
        min-width: 150px;
        align-items: center;
        justify-content: left;
        font-size: 15px;
    }
    .userList-users-interests{
        display: flex;
        flex-direction: row;
        min-width: 150px;
        align-items: center;
        justify-content: left;
        font-size: 15px;

    }
    .userList-pagination{
        display: flex;
        width: 90%;
        flex-direction: row;
        justify-content: space-between;
        
    }
    .userList-users-actions{
        display: flex;
        flex-direction: row ;
        justify-content: space-evenly;
        min-width: 200px;
        text-align: center;
    }
    .img-icons{
        width: 25px;
        /* height: 30px; */
        cursor:pointer;
    }
    .img-profile{
        width: 30px;
        height: 30px;
    }
    .userList-total-records > span {
        color: #727381;
        font-size: 12px;
    }
    .userList-total-records{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        justify-content: left;
    }
    
    
    .userList-pages{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        border: 1px solid #edf2f6;
    }
    .userList-page{
        padding: 5px 15px;
        background-color: #fff;
        color: #727381;
        cursor: pointer;
         margin-left: .5rem; 
        border-radius: 3px;
    }
    
    .userList-page.active {
        background-color: #0f26e0;
        color: #fff;
    }
    .userList-users-actions-single{
        color:#727381; 
        font-size:10px ; 
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: left;
        padding-right: 5px;
    }
   
    

}

@media only screen and (max-width:1300px){
    .mar{
        margin-left: 2.4rem;
    }
    .mar-l{
        margin-left: -2rem;
    }
}

@media only screen and ( max-width:1024px){
    .userList-container{
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        background-color: #edf2f6;
        font-family: 'HK Grotesk', sans-serif;
        font-weight: 600;
    }
    .userList-body-wrapper{
        display: flex;
        width: 80%;
        height: 70%;
        align-items: center;
        justify-content: center;
    }
    .userList-body{
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
    }
    .userList-users-container{
        display: flex;
        flex-direction: column;
        width: 100%;
        height:90%;
        flex: 1;
        
        align-items: center;
        justify-content: center;
        
    }
    .userList-users-container-inner{
        display: flex;
        width: 100%;
        flex-direction: column;
        overflow-y: auto;
        
    }
    
    .userList-users{
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-direction: row;
        border-radius: 5px;
        background-color: white;
        margin: 0.5rem  0px;
        padding: 0.3rem;
        
    }
    .userList-users-overall{
        display: flex;
        flex-direction: column;
        padding-bottom: 2px;
        min-height: 15px;
        width: 100%;
    }
    .userList-users-header{
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        border-radius: 3px;
        width: 100%;
        
    }
    .userList-users-img-header{
        display: flex;
        flex-direction: row;
        min-width: 30px;
        justify-content: left;
        font-size:15px; 
        color: #ffffff;

    }
    .userList-users-blank-header{
        display: flex;
        flex-direction: row;
        min-width: 150px;
        justify-content: left;
        font-size:8px; 
        color: #ffffff;

    }

    .userList-users-name-header{
        display: flex;
        flex-direction: row;
        min-width: 110px;
        justify-content: left;
        font-size:15px;  
        color: #ffffff;

        
    }
    .userList-users-desc-header{
        display: flex;
        flex-direction: row;
        min-width: 110px;
        justify-content: left;
        font-size:15px;   
        color: #ffffff;
        

    }
    .userList-users-company-header{
        display: flex;
        flex-direction: row;
        min-width: 110px;
        justify-content: left;
        font-size:15px;   
        color: #ffffff;
        

    }
    .userList-users-interests-header{
        display: flex;
        flex-direction: row;
        min-width: 110px;
        justify-content: left;
        font-size:15px;   
        color: #ffffff;
        
    }
    .userList-users-pic{
        display: flex;
        flex-direction: row;
        min-width: 30px;
        align-items: center;
        justify-content: center;
        
    }
    .userList-users-name{
        display: flex;
        flex-direction: row;
        min-width: 110px;
        align-items: center;
        justify-content: left;
        font-size: 12px;

    }
    .userList-users-desc{

        display: flex;
        flex-direction: row;
        min-width: 110px;
        align-items: center;
        justify-content: left;
        font-size: 12px;
    }
    .userList-users-company{
        
        display: flex;
        flex-direction: row;
        min-width: 110px;
        align-items: center;
        justify-content: left;
        font-size: 12px;
    }
    .userList-users-interests{
        display: flex;
        flex-direction: row;
        min-width: 110px;
        align-items: center;
        justify-content: left;
        font-size: 12px;

    }
    .userList-pagination{
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        
    }
    .userList-users-actions{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        min-width: 150px;
        text-align: center;
    }
    .img-icons{
        width: 20px;
        height: 20px;
        cursor:pointer;
    }
    .img-profile{
        width: 15px;
        height: 15px;
    }
    .userList-total-records > span {
        color: #727381;
        font-size: 10px;
    }
    .userList-total-records{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        justify-content: left;
    }
    
    
    .userList-pages{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        border: 1px solid #edf2f6;
    }
    .userList-page{
        padding: 2.5px 7.5px;
        background-color: #fff;
        color: #727381;
        cursor: pointer;
         /* margin-left: .5rem;  */
        border-radius: 3px;
    }
    
    .userList-page.active {
        background-color: #0f26e0;
        color: #fff;
    }
    .userList-users-actions-single{
        color:#727381; 
        font-size:6px ; 
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: left;
        padding-right: 5px;
    }
    

}
@media only screen and ( max-width:999px){
    .userList-container{
        display: flex;
        /* width: 100%; */
        width: 50%;
        margin-left: 15rem;
        /* height: 100%; */
        height: 100%;
        align-items: center;
        justify-content: center;
        background-color: #edf2f6;
        font-family: 'HK Grotesk', sans-serif;
        font-weight: 600;
    }
    .userList-body-wrapper{
        display: flex;
        width: 80%;
        height: 70%;
        align-items: center;
        justify-content: center;
       
      
    }
    .userList-body{
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
    }
    .userList-users-container{
        display: flex;
        flex: 1;
        flex-direction: column;
        width: 100%;
        /* height: 80%; */
        align-items: center;
        justify-content: space-between;
        

    }
    .userList-users-container-inner{
        display: flex;
        width: 100%;
        flex-direction: column;
        overflow-y: auto;
    }
    .userList-users{
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-direction: row;
        margin: 0.5rem 0px;
        padding: 0.3rem;
        border-radius: 5px;
        background-color: white;
    }
    .userList-users-overall{
        display: flex;
        flex-direction: column;
        min-height: 15px;
        width: 80%;
    }
    .userList-users-header{
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        width: 100%;
        border-radius: 2px;


    }
    .userList-users-img-header{
        display: flex;
        flex-direction: row;
        min-width: 30px;
        justify-content: left;
        font-size:8px; 
        color: #ffffff;

    }
    .userList-users-blank-header{
        display: flex;
        flex-direction: row;
        min-width: 100px;
        justify-content: left;
        font-size:8px; 
        color: #ffffff;

    }

    .userList-users-name-header{
        display: flex;
        flex-direction: row;
        min-width: 60px;
        justify-content: left;
        font-size:8px; 
        color: #ffffff;

        
    }
    .userList-users-desc-header{
        display: flex;
        flex-direction: row;
        min-width: 60px;
        justify-content: left;
        font-size:8px;  
        color: #ffffff;
        

    }
    .userList-users-company-header{
        display: flex;
        flex-direction: row;
        min-width: 60px;
        justify-content: left;
        font-size:8px;  
        color: #ffffff;
        

    }
    .userList-users-interests-header{
        display: flex;
        flex-direction: row;
        min-width: 60px;
        justify-content: left;
        font-size:8px;  
        color: #ffffff;
        
    }
    .userList-users-pic{
        display: flex;
        flex-direction: row;
        min-width: 30px;
        align-items: center;
        
    }
    .userList-users-name{
        /* display: flex;
        flex-direction: row;
        min-width: 60px;
        align-items: center;
        justify-content: left; */
        font-size: 7px;

    }
    .userList-users-desc{

        display: flex;
        flex-direction: row;
        min-width: 60px;
        align-items: center;
        justify-content: left;
        font-size: 7px;
    }
    .userList-users-company{
        
        display: flex;
        flex-direction: row;
        min-width: 60px;
        align-items: center;
        justify-content: left;
        font-size: 7px;
    }
    .userList-users-interests{
        display: flex;
        flex-direction: row;
        min-width: 60px;
        align-items: center;
        justify-content: left;
        font-size: 7px;

    }
    .userList-pagination{
        display: flex;
        width: 70%;
        flex-direction: row;
        justify-content: space-between;
        
    }
    .userList-users-actions{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        min-width: 100px;
        text-align: center;
    }
    .img-icons{
        width: 25px;
        /* height: 30px; */
        cursor:pointer;
    }
    .img-profile{
        width: 25px;
        height: 25px;
    }
    .userList-total-records > span {
        color: #727381;
        font-size: 10px;
    }
    .userList-total-records{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: left;
        justify-content: flex-start;
    }
    
    
    .userList-pages{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        border: 1px solid #edf2f6;
        font-size: 15px;
    }
    .userList-page{
        padding: 5px 6px;
        background-color: #fff;
        color: #727381;
        cursor: pointer;
         /* margin-left: .5rem;  */
        border-radius: 3px;
    }
    
    .userList-page.active {
        background-color: #0f26e0;
        color: #fff;
    }
    .userList-users-actions-single{
        color:#727381; 
        font-size:6px ; 
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: left;
        padding-right: 5px;
        position: relative;
        top: 0rem;
    }
    

}
@media only screen and ( max-width:760px){
    .userList-container {
            width: 70%;
            margin-left: 5rem;
        }
}
@media only screen and ( max-width:425px){
    .userList-container{
        display: flex;
        width: 100%;
        height: 100%;
        grid-template-columns: 70%;
        align-items: center;
        justify-content: center;
        background-color: #edf2f6;
        font-family: 'HK Grotesk', sans-serif;
        font-weight: 300;
    }
    .userList-body-wrapper{
        display: flex;
        width: 80%;
        height: 90%;
        align-items: center;
        justify-content: center;
    }
    .userList-body{
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
    }
    .userList-users-container{
        display: flex;
        flex: 1;
        flex-direction: column;
        width: 100%;
        height: 80%;
        align-items: center;
        justify-content: center;
    }
    .userList-users-container-inner{
        display: flex;
        width: 100%;
        flex-direction: column;
        overflow-y: auto;
    }
    .userList-users{
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        

    }
    .userList-users-overall{
        display: flex;
        flex-direction: column;
        min-height: 15px;
        width: 80%;
    }
    .userList-users-header{
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        width: 100%;


    }
    .userList-users-img-header{
        display: flex;
        flex-direction: row;
        min-width: 30px;
        justify-content: left;
        font-size:8px; 
        color: #727381;

    }
    .userList-users-blank-header{
        display: flex;
        flex-direction: row;
        min-width: 130px;
        justify-content: left;
        font-size:8px; 
        color: #727381;

    }

    .userList-users-name-header{
        display: flex;
        flex-direction: row;
        min-width: 60px;
        justify-content: left;
        font-size:8px; 
        color: #727381;

        
    }
    .userList-users-desc-header{
        display: flex;
        flex-direction: row;
        min-width: 60px;
        justify-content: left;
        font-size:8px;  
        color: #727381;
        

    }
    .userList-users-company-header{
        display: flex;
        flex-direction: row;
        min-width: 60px;
        justify-content: left;
        font-size:8px;  
        color: #727381;
        

    }
    .userList-users-interests-header{
        display: flex;
        flex-direction: row;
        min-width: 60px;
        justify-content: left;
        font-size:8px;  
        color: #727381;
        
    }
    .userList-users-pic{
        display: flex;
        flex-direction: row;
        min-width: 30px;
        align-items: center;
        
    }
    .userList-users-name{
        display: flex;
        flex-direction: row;
        min-width: 60px;
        align-items: center;
        justify-content: left;
        font-size: 7px;

    }
    .userList-users-desc{

        display: flex;
        flex-direction: row;
        min-width: 60px;
        align-items: center;
        justify-content: left;
        font-size: 7px;
    }
    .userList-users-company{
        
        display: flex;
        flex-direction: row;
        min-width: 60px;
        align-items: center;
        justify-content: left;
        font-size: 7px;
    }
    .userList-users-interests{
        display: flex;
        flex-direction: row;
        min-width: 60px;
        align-items: center;
        justify-content: left;
        font-size: 7px;

    }
    .userList-pagination{
        display: flex;
        width: 70%;
        flex-direction: row;
        justify-content: space-between;
        
    }
    .userList-users-actions{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        min-width: 130px;
        text-align: center;
    }
    .img-icons{
        width: 20px;
        height: 20px;
        cursor:pointer;
    }
    .img-profile{
        width: 15px;
        height: 15px;
    }
    .userList-total-records > span {
        color: #727381;
        font-size: 10px;
    }
    .userList-total-records{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }
    
    
    .userList-pages{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        border: 1px solid #edf2f6;
    }
    .userList-page{
        padding: 2.5px 7.5px;
        background-color: #fff;
        color: #727381;
        cursor: pointer;
         /* margin-left: .5rem;  */
        border-radius: 3px;
    }
    
    .userList-page.active {
        background-color: #0f26e0;
        color: #fff;
    }
    .userList-users-actions-single{
        color:#727381; 
        font-size:6px ; 
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: left;
        padding-right: 5px;
    }
    

}
@media only screen and ( max-width:375px){
    .userList-container{
        display: flex;
        width: 100%;
        height: 100%;
        grid-template-columns: 70%;
        align-items: center;
        justify-content: center;
        background-color: #edf2f6;
        font-family: 'HK Grotesk', sans-serif;
        font-weight: 300;
    }
    .userList-body-wrapper{
        display: flex;
        width: 80%;
        height:100%;
        align-items: center;
        justify-content: center;
    }
    .userList-body{
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
    }
    .userList-users-container{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 80%;
        align-items: center;
        justify-content: center;
        

    }
    .userList-users{
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        

    }
    .userList-users-overall{
        display: flex;
        flex-direction: column;
        min-height: 15px;
        width: 80%;
    }
    .userList-users-header{
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        width: 100%;


    }
    .userList-users-img-header{
        display: flex;
        flex-direction: row;
        min-width: 30px;
        justify-content: left;
        font-size:8px; 
        color: #727381;

    }
    .userList-users-blank-header{
        display: flex;
        flex-direction: row;
        min-width: 130px;
        justify-content: left;
        font-size:8px; 
        color: #727381;

    }

    .userList-users-name-header{
        display: flex;
        flex-direction: row;
        min-width: 60px;
        justify-content: left;
        font-size:8px; 
        color: #727381;

        
    }
    .userList-users-desc-header{
        display: flex;
        flex-direction: row;
        min-width: 60px;
        justify-content: left;
        font-size:8px;  
        color: #727381;
        

    }
    .userList-users-company-header{
        display: flex;
        flex-direction: row;
        min-width: 60px;
        justify-content: left;
        font-size:8px;  
        color: #727381;
        

    }
    .userList-users-interests-header{
        display: flex;
        flex-direction: row;
        min-width: 60px;
        justify-content: left;
        font-size:8px;  
        color: #727381;
        
    }
    .userList-users-pic{
        display: flex;
        flex-direction: row;
        min-width: 30px;
        align-items: center;
        
    }
    .userList-users-name{
        display: flex;
        flex-direction: row;
        min-width: 60px;
        align-items: center;
        justify-content: left;
        font-size: 7px;

    }
    .userList-users-desc{

        display: flex;
        flex-direction: row;
        min-width: 60px;
        align-items: center;
        justify-content: left;
        font-size: 7px;
    }
    .userList-users-company{
        
        display: flex;
        flex-direction: row;
        min-width: 60px;
        align-items: center;
        justify-content: left;
        font-size: 7px;
    }
    .userList-users-interests{
        display: flex;
        flex-direction: row;
        min-width: 60px;
        align-items: center;
        justify-content: left;
        font-size: 7px;

    }
    .userList-pagination{
        display: flex;
        width: 70%;
        flex-direction: row;
        justify-content: space-between;
        
    }
    .userList-users-actions{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        min-width: 130px;
        text-align: center;
    }
    .img-icons{
        width: 20px;
        height: 20px;
        cursor:pointer;
    }
    .img-profile{
        width: 15px;
        height: 15px;
    }
    .userList-total-records > span {
        color: #727381;
        font-size: 10px;
    }
    .userList-total-records{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }
    
    
    .userList-pages{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        border: 1px solid #edf2f6;
    }
    .userList-page{
        padding: 2.5px 7.5px;
        background-color: #fff;
        color: #727381;
        cursor: pointer;
         /* margin-left: .5rem;  */
        border-radius: 3px;
    }
    
    .userList-page.active {
        background-color: #0f26e0;
        color: #fff;
    }
    .userList-users-actions-single{
        color:#727381; 
        font-size:6px ; 
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: left;
        padding-right: 5px;
    }
    
}
@media only screen and ( max-width:320px){
    .userList-container{
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        background-color: #edf2f6;
        font-family: 'HK Grotesk', sans-serif;
        font-weight: 300;
    }
    .userList-body-wrapper{
        display: flex;
        width: 80%;
        height: 100%;
        align-items: center;
        justify-content: center;
       
      
    }
    .userList-body{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0;
        align-items: center;
        justify-content: center;
    }
    .userList-users-container{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 80%;
        align-items: center;
        justify-content: center;
        

    }
    .userList-users{
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-direction: row;
        

    }
    .userList-users-overall{
        display: flex;
        flex-direction: column;
        min-height: 15px;
        width: 80%;
    }
    .userList-users-header{
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        width: 100%;


    }
    .userList-users-img-header{
        display: flex;
        flex-direction: row;
        min-width: 30px;
        justify-content: left;
        font-size:8px; 
        color: #727381;

    }
    .userList-users-blank-header{
        display: flex;
        flex-direction: row;
        min-width: 130px;
        justify-content: left;
        font-size:8px; 
        color: #727381;

    }

    .userList-users-name-header{
        display: flex;
        flex-direction: row;
        min-width: 60px;
        justify-content: left;
        font-size:8px; 
        color: #727381;

        
    }
    .userList-users-desc-header{
        display: flex;
        flex-direction: row;
        min-width: 60px;
        justify-content: left;
        font-size:8px;  
        color: #727381;
        

    }
    .userList-users-company-header{
        display: flex;
        flex-direction: row;
        min-width: 60px;
        justify-content: left;
        font-size:8px;  
        color: #727381;
        

    }
    .userList-users-interests-header{
        display: flex;
        flex-direction: row;
        min-width: 60px;
        justify-content: left;
        font-size:8px;  
        color: #727381;
        
    }
    .userList-users-pic{
        display: flex;
        flex-direction: row;
        min-width: 30px;
        align-items: center;
        
    }
    .userList-users-name{
        display: flex;
        flex-direction: row;
        min-width: 60px;
        align-items: center;
        justify-content: left;
        font-size: 7px;

    }
    .userList-users-desc{

        display: flex;
        flex-direction: row;
        min-width: 60px;
        align-items: center;
        justify-content: left;
        font-size: 7px;
    }
    .userList-users-company{
        
        display: flex;
        flex-direction: row;
        min-width: 60px;
        align-items: center;
        justify-content: left;
        font-size: 7px;
    }
    .userList-users-interests{
        display: flex;
        flex-direction: row;
        min-width: 60px;
        align-items: center;
        justify-content: left;
        font-size: 7px;

    }
    .userList-pagination{
        display: flex;
        width: 70%;
        flex-direction: row;
        justify-content: space-between;
        
    }
    .userList-users-actions{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        min-width: 130px;
        text-align: center;
    }
    .img-icons{
        width: 20px;
        height: 20px;
        cursor:pointer;
    }
    .img-profile{
        width: 15px;
        height: 15px;
    }
    .userList-total-records > span {
        color: #727381;
        font-size: 10px;
    }
    .userList-total-records{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }
    
    
    .userList-pages{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        border: 1px solid #edf2f6;
    }
    .userList-page{
        padding: 2.5px 7.5px;
        background-color: #fff;
        color: #727381;
        cursor: pointer;
         /* margin-left: .5rem;  */
        border-radius: 3px;
    }
    
    .userList-page.active {
        background-color: #0f26e0;
        color: #fff;
    }
    .userList-users-actions-single{
        color:#727381; 
        font-size:6px ; 
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: left;
        padding-right: 5px;
    }
    
}
.circle_dar{
    width: 60px;
    border-radius: 50%;
    background: black;
    color: white;
    padding: 11px;
    margin-left: 17rem;
}


.mtulitgrid{
    padding: 8%;
    width: 100%;
}
