@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800");
.User-area{
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
}

.User-area {
    position: relative;
    cursor: pointer;
    float: right;
    margin: 9px 13px;
    background-color: #0739ff;
    color: #fff;
    padding: 10px;
}
.User-area > .User-avtar {
    margin: 0px 0px;
    border-radius: 60%;
 
}
.User-area > .User-avtar img {
    margin: 1px;  
    width:30px; 
}
.User-area > .User-avtar > .user-icon {
    margin: 0px -6px;
    border-radius: 50%;
    visibility: unset !important;
}

.User-Dropdown {
    display: none;
    position: absolute;
    border-radius: 7px;
    background-color: #0739ff;
    color: #fff;
    box-shadow: 0px 0px 8px rgba(214, 214, 214, 0.78);
    list-style: none;
    padding: 0 20px;
    width: 15rem;
    margin: 0;
    top: 0;
    left: -15rem;
    height: fit-content;
    z-index: 99;
}
.User-Dropdown:before {
    /* content: "";
    position: absolute;
    width: 0;
    height: 0;
    margin-right: -0.5em;
    left: 0rem;
    box-sizing: border-box;
    border: 7px solid black;
    border-color: transparent transparent #ffffff #ffffff;
    transform-origin: 0 0;
    transform: rotate(45deg);
    box-shadow: -3px 3px 3px -3px rgba(214, 214, 214, 0.78);
    top: 8rem; */
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    margin-right: -0.5em;
    left: 15rem;
    box-sizing: border-box;
    border: 7px solid black;
    border-color: transparent transparent #ffffff #ffffff;
    transform-origin: 0 0;
    transform: rotate(45deg);
    box-shadow: -3px 3px 3px -3px rgba(214, 214, 214, 0.78);
    top: 1rem;
}
.User-Dropdown.U-open {
    display: block;
}
.User-Dropdown > li {
    padding: 0px;
    line-height: 30px;
    border-bottom: 1px solid rgba(215, 215, 215, 0.17);
}
.User-Dropdown > li:last-child {
    border-bottom: 0px;
}
.User-Dropdown > li > a {
    font-size: 13px;
    padding: 15px 25px 16px 10px;
    text-decoration: none;
    color: #0739ff;
    transition: all 0.2s ease-out;
    vertical-align: super;
}
.User-Dropdown span {
    font-size: 13px;
}
.User-Dropdown > li:before {
    content: "";
    width: 0px;
    height: 40px;
    position: absolute;
    background: #fff;
    margin-top: 4px;
    border-radius: 0 1px 1px 0;
    left: 0px;
    transition: all 0.2s ease;
}
.User-Dropdown > li:hover:before {
    width: 5px;
    border-radius: 30px;
}
.User-Dropdown > li a:hover {
    margin-left: 5px;
}

.user-profile {
    display: flex;
    margin: 5px;
    padding: 10px;
    align-items: baseline;
}

.user-pro-details {
    line-height: 1rem;
    margin-left: 1rem;
}

@media only screen and (min-width:768px) and (max-width:1023px) {
    .User-Dropdown {
        display: none;
        position: absolute;
        border-radius: 7px;
        background-color: #0739ff;
        color: #fff;
        box-shadow: 0px 0px 8px rgb(214 214 214 / 78%);
        list-style: none;
        padding: 0 20px;
        width: 15rem;
        margin: 0;
        top: 0rem;
        left: -15rem;
        height: fit-content;
    }

    .User-Dropdown > li {
        padding: 0px;
        line-height: 2rem;
        border-bottom: 1px solid rgba(215, 215, 215, 0.17);
        white-space: pre;
    }

    .User-area {
        position: relative;
        cursor: pointer;
        /* float: left; */
        float: right;
        margin: 9px 0px;
        background-color: #0739ff;
        color: #fff;
    }
}

@media only screen and (max-width:767px) {
    .User-Dropdown {
        display: none;
        position: absolute;
        border-radius: 7px;
        background-color: #0739ff;
        color: #fff;
        box-shadow: 0px 0px 8px rgb(214 214 214 / 78%);
        list-style: none;
        padding: 0 20px;
        width: 15rem;
        margin: 0;
        top: 0rem;
        left: -15rem;
        height: fit-content;
    }

    .User-Dropdown > li {
        padding: 0px;
        line-height: 2rem;
        border-bottom: 1px solid rgba(215, 215, 215, 0.17);
        white-space: pre;
    }

    .User-area {
        z-index: 1;
        position: relative;
        cursor: pointer;
        /* float: left; */
        float: right;
        margin: 9px 0px;
        background-color: #0739ff;
        color: #fff;
    }
}

@media only screen and (min-width: 1000px ){
    .User-Dropdown:before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        margin-right: -0.5em;
        left: 0rem;
        box-sizing: border-box;
        border: 7px solid black;
        border-color: transparent transparent #ffffff #ffffff;
        transform-origin: 0 0;
        transform: rotate(45deg);
        box-shadow: -3px 3px 3px -3px rgba(214, 214, 214, 0.78);
        top: 6rem;
    }
    .User-Dropdown {
        display: none;
        position: absolute;
        border-radius: 7px;
        background-color: #0739ff;
        color: #fff;
        box-shadow: 0px 0px 8px rgba(214, 214, 214, 0.78);
        list-style: none;
        padding: 0 20px;
        width: 15rem;
        margin: 0;
        top: -5rem;
        left: 5rem;
        height: -moz-fit-content;
        height: fit-content;
        z-index: 99;
    }


}