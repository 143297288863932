
 model-viewer{
  min-width: 100%;
  min-height:100%;
  border: 1px solid black;
  margin: 5px 0px;
  
 }


 model-viewer::part(default-progress-bar){
 height: 5%;
 background: linear-gradient(to right, rgb(187, 177, 209) 0%, rgb(91, 65, 158) 100%);;
 }


 
 .modelviewform{
  width:100%;
  display: flex;
  flex-direction: column;
  background-color: rgb(177, 159, 221);
 box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;;
 }

 .modelviewform span{
  width:100%;
  text-align:center;
  color:white;
  font-family: 'HelveticaNeue-UltraLight', 'Helvetica Neue UltraLight', Roboto, Arial, Sans-serif;
  font-size:inherit;
 

 }
 .modelsliders{
  -webkit-appearance:none;
  width:160px;
  height:20px;
  margin:5px 10px;
  background: linear-gradient(to right, rgb(124, 108, 160) 0%, rgb(91, 65, 158) 100%);
  background-size:150px 10px;
  background-position:center;
  background-repeat:no-repeat;
  overflow:hidden;
  outline: none;
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.3);;
 }
 .modelviewcontainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
 }

 .modelviewcontainer span{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width:auto;
  color: rgb(255, 255, 255);
 }

 .modelsliders::-webkit-slider-thumb:after{
  content:" ";
  width:160px;
  height:10px;
  position:absolute;
  z-index:1;
  right:20px;
  top:5px;
  background: #28253d;
  
}

.modelsliders::-webkit-slider-thumb{
  -webkit-appearance:none;
  width:20px;
  height:20px;
  background:#403677;
  position:relative;
  z-index:3;
  box-shadow:0 0 5px 0 rgba(0,0,0,0.3);
}


 .slider{

  display: flex;
  align-items: center;
  justify-content: space-around;

 }
 

@media (min-width: 2561px){
  .total{
    display: grid;
    grid-template-rows: auto auto;
    width: 100%;
  height: 100%;
  }
  model-viewer{
    height:600px;
   }

 

}

 @media (max-width:2560px) {
  .total{
    display: grid;
    grid-template-rows: auto auto;
    width: 100%;
  height: 100%;
  }
  model-viewer{
    height:500px;
   }


 
}

@media only screen and (max-width:1440px) {
  .total{
    display: grid;
    grid-template-rows: auto auto;
    width: 100%;
    height: 100%;
  }

  
 model-viewer{
  height:400px;
 }


 
}


@media  (max-width:1024px){
  
.total{
  display: grid;
    grid-template-rows: auto auto;
    width: 100%;
  height: 100%;
 }

 model-viewer{
  height:120px;
 }

 


}

@media  (max-width:768px){
  

  .total{
    display: grid;
    grid-template-rows: auto auto;
    width: 100%;
  height: 100%;
  
   }

   model-viewer{
    height:95px;
   }

  
   
}





