.videoCall-container{
    width: 350px;
    height: 80px;
    display: flex;
    align-items: baseline;
    justify-content: space-around;
    -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
}

.videocall-name{
    font-weight: 600;
    font-size: 20px;
}

.videocall-type{
    font-weight: lighter;
    font-size: 14px;
    letter-spacing: 1px;
}

.videocall-actions{
    background-color: #4724da;
    color: white;
    padding: 4px 10px;
    border-radius: 20%;
    cursor: pointer;
}

.notifyclose{
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    padding: 12px;
    cursor: pointer;
}