.floating-menu-container-userList {
    position: absolute;
    z-index: 999;
    width: min-content;
    display: flex;
    background: #0739ff;
    padding: 3px;
    flex-direction: column;
    justify-content: flex-start;
    height: 100vh;
    padding-top: 8rem;
}

.floating-menu-container{
    position: absolute;
    z-index: 999;
    /* top: 5rem; */
    top:20%;
    left: 10px;
    width: min-content;
    display: flex;
    background: #0739ff;
    padding: 2px;
    border-radius: 5px;
    flex-direction: column;
}
/* global css */
.productDescription {
    word-break: break-all;
   
}

.boothDocument{
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width:55ch;
}
.documentTbody td, .documentTbody th{
    vertical-align: middle;
}
.boothDocumentDownloadButton{
    font-size: 15px;;
}
.brandLogo1{
    width:12.4%;
}
@media only screen and (max-width:1400px) {
    .brandLogo1{
        width:5.9%;
    }
}
@media only screen and (max-width:1024px) {
    .brandLogo1{
        width:7.9%;
    }
    
}
@media only screen and (max-width:825px) {
    .brandLogo1 {
        width: 8.9%;
    }
    .floating-menu-container-userList{
        padding-top: 2.5rem;
        height: auto;
    }
    .floating-menu-container-userList div img{
        width: 66px;
        height: 49px;
      }
      .floating-menu-container-userList div{
        padding: 0px !important;
        height:50px;
    }
    
}
@media only screen and (max-width:767px) {
    .floating-menu-container {
        position: fixed;
        z-index: 999;
        /* top: 5rem; */
        top:20%;
        left: 7px;
        width: 33px !important;
        background: #0739ff;
        padding: 0px;
        border-radius: 5px;
    }
    .floating-menu-container > div > img{
      display: block !important;
      width: 85% !important;
      height: auto !important;
    }
    
}
@media only screen and (min-width:768px) and (max-width:1023px) {
    .boothDocument{
        font-size: 14px;
    }
    .businessCardIcons button{
        margin-right:10px;
    }
    .floating-menu-container {
      position: fixed;
      z-index: 999;
      top: 20%;
      left: 7px;
      width: 43px !important;
      background: #0739ff;
      padding: 0px;
      border-radius: 5px;
  }
  .floating-menu-container > div > img{
    display: block !important;
    /* width: 85% !important; */
    width: 78% !important;
    height: auto !important;
  }
  .floating-menu-container > div > div{
    display: block !important;
    width: 85% !important;
    height: auto !important;
  }
  
  .makeStyles-menuIconButtonContainer-10{
    top: 15%;
    right: 0.5% !important;
    padding: 3px;
    z-index: 9999;
    position: fixed;
    background-image: linear-gradient(
   90deg,#f66464,#0739ff 60%,#4eb589);
  }
  .businessCardImage{
      width: 160px !important;
      height: 186px !important;
      
  }
  .businessCardContainer{
      width: 383px !important;
      height: 186px;
  }
  .floating-menu-container-userList{
    padding-top: 2.3rem;
    height: auto;
}
.floating-menu-container-userList div{
    padding: 0px !important;
    height:49px;
}
.floating-menu-container-userList div img{
  width: 48px;
  height: 48px;
}
}

@media only screen and (max-width:650px) {
    .brandLogo1 {
        width:12.4%;
    }
    .floating-menu-container{
        top:9.5em;
    }
    .videoDisplay div .videoDisplay div{
        width: 171px !important;
        height: 106px !important;
    }
    .videoDisplay div video,.videoDisplay div iframe{
        width: 171px !important;
        height: 96px !important;
    }
    .boothDocumentDownloadButton{
        padding-left:2px !important;
        padding-right: 2px !important;
    }
   
}

.floating-img-others{
    display: block;
    width: 55px;
    height: auto;
  }

  .floating-img-booth{
    display: block;
    width: 50px;
    height: auto;
  }
  .floating-menu-container > div {
    height: 85%;
  }

.mt-4{
      margin-top: 4rem;
}


@media only screen and (max-width: 2560px){
    .brandLogo{
        cursor: pointer;
        position:fixed;
        bottom: 20px;
        right:20px;
        width: 80px;
        height: 80px;
        z-index:9999;
        
    }
    

  }
  @media only screen and (max-width: 1440px){
    .brandLogo{
        cursor: pointer;
        position:fixed;
        bottom: 20px;
        right:20px;
        z-index:9999;
        height: 65px;
        width: 65px;
    }

}
@media only screen and (max-width: 1024px){
    .brandLogo{
        cursor: pointer;
        position: fixed;
        bottom: 20px;
        right: 20px;
        z-index: 9999;
        height: 60px;
        width: 60px;
       }

}
@media only screen and (max-width: 768px){
   .brandLogo{
    cursor: pointer;
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 9999;
    height: 40px;
    width: 40px;
   }

}
@media only screen and (max-width: 425px){
    .brandLogo{
        cursor: pointer;
        position:fixed;
        bottom: 20px;
        right:20px;
        z-index:9999;
        height: 35px;
        width: 35px;
    }

}
@media only screen and (max-width: 375px){
    .brandLogo{
        cursor: pointer;
        position:fixed;
        bottom: 20px;
        right:20px;
        z-index:9999;
        height: 35px;
        width: 35px;
    }

}
@media only screen and (max-width: 320px){
    .brandLogo{
        cursor: pointer;
        position:fixed;
        bottom: 20px;
        right:20px;
        z-index:9999;
        height: 35px;
        width: 35px;
    }

}
/* @media only screen and (max-width: 580px){
    .modalcontainer{
        width: 86%;
        margin-left: 1.5rem;
    }
}

@media only screen and (max-width: 999px){
    .modalcontainer{
        margin-top: -1rem;
    }
} */

.mob-div{
    margin: 0px 2px; 
    padding: 1px 
}
@media only screen and (max-width: 580px){
    .mob-div{
        margin:0px
    }
}
@media only screen and (max-width: 999px){
.modalcontainer .modal-dialog .modal-content{
    width: 110%;
}}
@media only screen and (max-width: 750px) {
    .modalcontainer .modal-dialog .modal-content {
        width: 106%;
    }
}

@media only screen and (max-width: 645px) {
    .modalcontainer .modal-dialog .modal-content {
        width: 102%;
    }
}