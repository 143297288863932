@import url("https://cdn.jsdelivr.net/npm/@xz/fonts@1/serve/hk-grotesk.min.css");
.mybox-container {
    font-family: 'HK Grotesk', sans-serif;
    font-weight: 600;
}
.mybox-container{
    max-width: calc(100% - 85px);
    height: 100vh;
    background-color: #edf2f6;
    margin-left: auto;
}

.mybox-body{
    max-width: calc(100% - 80px);
    max-height: calc(100% - 30px);
    display: flex;
    flex-direction: column;
    padding-top: 3rem;
        margin: auto;
}

.mybox-header {
         margin-bottom: 1rem;
        text-align: center;
        text-transform: uppercase;
        /* text-decoration: underline overline dotted red 5px; */
         text-indent: 50px; 
         white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;  
        text-shadow: 0 1px 0 #ccc,
        0 2px 0 #c9c9c9,
        0 3px 0 #bbb,
        0 4px 0 #b9b9b9,
        0 5px 0 #aaa,
        0 6px 1px rgba(0,0,0,.1),
        0 0 5px rgba(0,0,0,.1),
        0 1px 3px rgba(0,0,0,.3),
        0 3px 5px rgba(0,0,0,.2),
        0 5px 10px rgba(0,0,0,.25),
        0 10px 10px rgba(0,0,0,.2),
        0 20px 20px rgba(0,0,0,.15);
    
}
.mybox-header > span{
   font-size: 2.5rem;
   font-weight: 600;
}

.mybox-navigation{
    display: flex;
    background-color: #b6bbc5;
    margin-bottom: 1rem;
    width: 100%;
    cursor: pointer;
    flex-wrap:wrap;
}

.mybox-nav-option{
    padding: 8px 25px;
}

.mybox-navigation > div  > span {
    white-space: nowrap;
}

.mybox-border{
    border-bottom: 2px solid #000;
    width: 100%;
}

.mybox-nav-option.active {
    background: rgb(71, 36, 218);
    color: #fff;
}

.mybox-data{
    width: 100%;
    height: 100%;
    overflow-y: auto;
}
/* responsive  css patches*/
.leaderBoardImg{
    width: 41px;
    height: 41px;
}
body{
    background: #edf2f6;
}

@media only screen and (max-width: 670px) {
    .mybox-header > span {
        font-size: 1rem;
        font-weight: 600;
    }
    .mybox-nav-option {
        padding: 4px 19px;
    }
    .mybox-header{
        /* margin-bottom: 0; */
        margin:20px;
    }
    .mybox-navigation{
        margin-bottom: 0.11rem;
    }
    .listing-users-container {
        display: flex;
        flex-direction: column;
        padding: 3px;
        margin-bottom: 2rem;
        font-size: 1rem;
    }
    .listing-users-header > div > span {
        color:#000;
        font-size:13px;
    }
    .listing-users {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: max-content;
        padding: 1px 15px;
        margin-bottom: 0.5rem;
        background-color: #fff;
        align-items: center;
    }
    .listing-users > div > span {
        color: #000;
        font-size: 13px;
    }
    .listing-users-actions > div > img {
        width: 28px;
        height: 28px;
        margin-left:7px;
        cursor: pointer;
    }
    .listing-users-actions > div > span{
        color: #000;
    }
    .User-area{
        width: 37px;
        height: 37px;
    }
    .leaderBoardImg{
        width: 31px;
        height: 31px;
    }
    .mybox-body {
        padding-top: 1.8rem;
    }
    .mybox-data {
        width: 100%;
        min-height: 100px;
        max-height: 101px;
        overflow-y: auto;
    }
    .floating-menu-container-userList {
        
        padding-top: 2.7rem !important;
    }
    .floating-menu-container-userList div img {
        width: 50px !important;
        height: 36px !important;
    }
    
}
@media only screen and (max-width: 655px) {
    .brandLogo1{
        width: 65px !important;
    }
    .floating-menu-container-userList{
        
        height: 100vh;
    }
    .floating-menu-container-userList div{
        margin: -5.5px 1px !important;
        
    }
    
    .floating-menu-container-userList div{
        
        height:auto !important;
    }

}
 /* responsive  end css patches*/ 
