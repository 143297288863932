.search-pop .modal-dialog {
    max-width: 900px;
}



.search-pop .modal-header {
    background: #0739ff;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
}


.search-pop .card-body {
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .2);
}

.search-pop label {
    font-weight: 600;
}

.search-pop input[type=radio] {
    margin-right: 5px;
}

.search-pop button {
    border: none;
    background: #0739ff;
    padding: 5px 20px;
    color: #fff;
    border-radius: 5px;
}

.search-pop button:hover{
   background: #222;
}


.search-pop .card .card-body .card-text {
    font-weight: 400;
    font-size: 16px;
    color: #222;
}

.search-pop .card-title {
    color: #0739ff;
}

.search-pop .card-deck {
    color: red;
    font-weight: bold;
    font-size: 14px;
    padding-left: 15px;
}

.search-pop .modal-header .close {
    right: 5px;
    top: 0px;
}

.search-pop .modal-dialog .modal-content {
    width: 96%;
    margin-left: 10px;
}