/* .grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 12px;
}

.item {
  min-height: 200px;
  background-color: #eee;
} */

@import url("https://cdn.jsdelivr.net/npm/@xz/fonts@1/serve/hk-grotesk.min.css");

@media only screen and (min-width: 2561px ){
  .businessvcard-overall{
    display: grid;
    width: 100%;
    height: 100%;
    justify-content: center;
    font-family: 'HK Grotesk', sans-serif;
  
  
  }
  .businessvcard-wrapper{
    display: grid;
    grid-template-columns: auto auto;
    grid-gap:10px !important;
   align-items: center;
   justify-content: left;
    width: 80%;
    height: 80%;
  
  }
  .businessvcard-card{
    display: grid;
    grid-template-rows: 70% 30%;
    grid-gap:10px!important;
    min-width: 350px;
    margin: 15px;
  }
  .businessvcard-data{
    display: grid;
    grid-template-columns: 40% 60%;
    min-width: 300px;
    margin: 10px;
    
  }
  .businessvcard-image-container{
    display: grid;
    width: auto;
    grid-template-columns: 100%;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    margin-right: 10px;
  
  
  }
  .businessvcard-contents-container{
    display: grid;
    grid-template-rows: auto auto auto;
    grid-gap: 10px !important;
  
  }
  .businessvcard-contents-name-container{
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: left;
  }
  .businessvcard-contents-name{
    display: grid;
    grid-auto-rows: auto auto ;
    
  
  }
  
  .businessvcard-contents-phone{
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: left;
    
  
  }
  .businessvCardIcons{
    justify-content: space-evenly;
    
  }
  /* span{
    font-style: bold;
    font-weight: 900;
    font-size: 15px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  } */

  .businessvcard-icon-background{
    display: grid;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-image: linear-gradient(45deg,rgb(145, 79, 207),indigo,rgb(131, 36, 255));
    border-radius: 4px;

  }
  .businessvcard-icon-black{
    display: grid;
    width: 35px;
    height: 35px;
    align-items: center;
    justify-content: center;
    background-color: black;
    border-radius: 4px;


  }
  

}

@media only screen and (max-width: 2560px){
  .businessvcard-overall{
    display: grid;
    width: 100%;
    height: 100%;
    justify-content: center;
    font-family: 'HK Grotesk', sans-serif;
  
  
  }
  .businessvcard-wrapper{
    display: grid;
    grid-template-columns: auto auto;
    grid-gap:10px !important;
   align-items: center;
   justify-content: left;
    width: 80%;
    height: 80%;
  
  }
  .businessvcard-card{
    display: grid;
    grid-template-rows: 70% 30%;
    grid-gap:10px!important;
    min-width: 350px;
    margin: 15px;
  }
  .businessvcard-data{
    display: grid;
    grid-template-columns: 40% 60%;
    min-width: 300px;
    margin: 10px;
    
  }
  .businessvcard-image-container{
    display: grid;
    width: auto;
    grid-template-columns: 100%;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    margin-right: 10px;
  
  
  }
  .businessvcard-contents-container{
    display: grid;
    grid-template-rows: auto auto auto;
    grid-gap: 10px !important;
  
  }
  .businessvcard-contents-name-container{
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: left;
  }
  .businessvcard-contents-name{
    display: grid;
    grid-auto-rows: auto auto ;
    
  
  }
  
  .businessvcard-contents-phone{
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: left;
    
  
  }
  .businessvCardIcons{
    justify-content: space-evenly;
    
  }
  /* span{
    font-style: bold;
    font-weight: 900;
    font-size: 15px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  } */

  .businessvcard-icon-background{
    display: grid;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-image: linear-gradient(45deg,rgb(145, 79, 207),indigo,rgb(131, 36, 255));
    border-radius: 4px;

  }
  .businessvcard-icon-black{
    display: grid;
    width: 35px;
    height: 35px;
    align-items: center;
    justify-content: center;
    background-color: black;
    border-radius: 4px;


  }
  
  
  

}
@media only screen and (max-width: 1400px){
  .businessvcard-overall{
    display: grid;
    width: 100%;
    height: 100%;
    justify-content: center;
    font-family: 'HK Grotesk', sans-serif;
  
  
  }
  .businessvcard-wrapper{
    display: grid;
    grid-template-columns: auto auto;
   align-items: center;
   justify-content: left;
    width: 80%;
    height: 80%;
  
  }
  .businessvcard-card{
    display: grid;
    grid-template-rows: 70% 30%;
    grid-gap:10px !important;
    min-width: 350px;
    margin: 10px;
  
  }
  .businessvcard-data{
    display: grid;
    grid-template-columns: 40% 60%;
    min-width: 300px;
    margin: 10px;
    
  }
  .businessvcard-image-container{
    display: grid;
    width: auto;
    grid-template-columns: 100%;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    margin-right: 10px;
  
  
  }
  .businessvcard-contents-container{
    display: grid;
    grid-template-rows: auto auto auto;
    grid-gap: 10px !important;
  
  }
  .businessvcard-contents-name-container{
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: left;
  }
  .businessvcard-contents-name{
    display: grid;
    grid-auto-rows: auto auto ;
    
  
  }
  
  .businessvcard-contents-phone{
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: left;
    
  
  }
  /* span{
    font-style: bold;
    font-weight: 900;
    font-size: 15px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  } */
  
  
  

}
@media only screen and (max-width: 1024px){
  .businessvcard-overall{
    display: grid;
    width: 100%;
    height: 100%;
    justify-content: center;
    font-family: 'HK Grotesk', sans-serif;
  
  
  }
  .businessvcard-wrapper{
    display: grid;
    grid-template-columns: auto ;
    grid-gap:10px !important;
   align-items: center;
   justify-content: left;
    width: 100%;
    height: 100%;
  
  }
  .businessvcard-card{
    display: grid;
    grid-template-rows: 70% 30%;
    grid-gap: 10px !important;
    min-width: 300px;
    margin: 10px;
  

  }
  .businessvcard-data{
    display: grid;
    grid-template-columns: 40% 60%;
    min-width: 300px;
    margin: 10px;
    
  }
  .businessvcard-image-container{
    display: grid;
    width: auto;
    grid-template-columns: 100%;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    margin-right: 10px;
  
  
  }
  .businessvcard-contents-container{
    display: grid;
    grid-template-rows: auto auto auto;
    grid-gap: 10px !important;
  
  }
  .businessvcard-contents-name-container{
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: left;
  }
  .businessvcard-contents-name{
    display: grid;
    grid-auto-rows: auto auto ;
    
  
  }
  
  .businessvcard-contents-phone{
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: left;
    
  
  }
  /* span{
    font-style: bold;
    font-weight: 900;
    font-size: 15px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
   */
  
  

}
@media only screen and (max-width: 768px){
  .businessvcard-overall{
    display: grid;
    width: 100%;
    height: 100%;
    justify-content: center;
    font-family: 'HK Grotesk', sans-serif;
  
  
  }
  .businessvcard-wrapper{
    display: grid;
    grid-template-columns: auto ;
    grid-gap:10px !important;
   align-items: center;
   justify-content: left;
    width: 100%;
    height: 100%;
  
  }
  .businessvcard-card{
    display: grid;
    grid-template-rows: 70% 30%;
    grid-gap: 10px !important;
    min-width: 350px;
    margin: 10px;
  
  }
  
 
  .businessvcard-data{
    display: grid;
    grid-template-columns: 40% 60%;
    min-width: 300px;
    margin: 10px;
    
  }
  .businessvcard-image-container{
    display: grid;
    width: auto;
    grid-template-columns: 100%;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    margin-right: 10px;
  
  
  }
  .businessvcard-contents-container{
    display: grid;
    grid-template-rows: auto auto auto;
    grid-gap: 10px !important;
  
  }
  .businessvcard-contents-name-container{
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: left;
  }
  .businessvcard-contents-name{
    display: grid;
    grid-auto-rows: auto auto ;
    
  
  }
  
  .businessvcard-contents-phone{
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: left;
    
  
  }
  /* span{
    font-style: bold;
    font-weight: 900;
    font-size: 15px;
    font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
   */
  

}
@media only screen and (max-width: 425px){
  .businessvcard-overall{
    display: grid;
    width: 100%;
    height: 100%;
    font-family: 'HK Grotesk', sans-serif;
  
  
  }
  .businessvcard-wrapper{
    display: grid;
    grid-template-columns: auto ;
    grid-gap:10px !important;
   align-items: center;
   justify-content: left;
    width: 80%;
    height: 80%;
  
  }
  .businessvcard-card{
    display: grid;
    grid-template-rows: 70% 30%;
    grid-gap:10px !important;
    min-width: 300px;
    margin: 10px;

  
  }
  .businessvcard-data{
    display: grid;
    grid-template-columns: 40% 60%;
    min-width: 300px;
    margin: 10px;
    
  }
  .businessvcard-image-container{
    display: grid;
    width: auto;
    grid-template-columns: 100%;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    margin-right: 10px;
  
  
  }
  .businessvcard-contents-container{
    display: grid;
    grid-template-rows: auto auto auto;
    grid-gap: 10px !important;
  
  }
  .businessvcard-contents-name-container{
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: left;
  }
  .businessvcard-contents-name{
    display: grid;
    grid-auto-rows: auto auto ;
    
  
  }
  
  .businessvcard-contents-phone{
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: left;
    
  
  }
  /* span{
    font-style: bold;
    font-weight: 900;
    font-size: 10px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  } */
  
  

}
@media only screen and (max-width: 375px){
  .businessvcard-overall{
    display: grid;
    width: 100%;
    height: 100%;
    font-family: 'HK Grotesk', sans-serif;
  
  
  }
  .businessvcard-wrapper{
    display: grid;
    grid-template-columns: auto ;
    grid-gap:10px !important;
   align-items: center;
   justify-content: left;
    width: 80%;
    height: 80%;
  
  }
  .businessvcard-card{
    display: grid;
    grid-template-rows: 70% 30%;
    grid-gap:10px !important;
    min-width: 300px;
    margin: 10px;
  
  }
  .businessvcard-data{
    display: grid;
    grid-template-columns: 40% 60%;
    min-width: 300px;
    margin: 10px;
    
  }
  .businessvcard-image-container{
    display: grid;
    width: auto;
    grid-template-columns: 100%;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    margin-right: 10px;
  
  
  }
  .businessvcard-contents-container{
    display: grid;
    grid-template-rows: auto auto auto;
    grid-gap: 10px !important;
  
  }
  .businessvcard-contents-name-container{
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: left;
  }
  .businessvcard-contents-name{
    display: grid;
    grid-auto-rows: auto auto ;
    
  
  }
  
  .businessvcard-contents-phone{
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: left;
    
  
  }
  /* span{
    font-style: bold;
    font-weight: 900;
    font-size: 10px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  } */
  
  

}
@media only screen and (max-width: 320px){
  .businessvcard-overall{
    display: grid;
    width: 100%;
    height: 100%;
    font-family: 'HK Grotesk', sans-serif;
  
  
  }
  .businessvcard-wrapper{
    display: grid;
    grid-template-columns: auto ;
    grid-gap:10px !important;
   align-items: center;
   justify-content: left;
    width: 80%;
    height: 80%;
  
  }
  .businessvcard-card{
    display: grid;
    grid-template-rows: 70% 30%;
    grid-gap:10px !important;
    min-width: 300px;
    margin: 10px;

  
  }
  .businessvcard-data{
    display: grid;
    grid-template-columns: 40% 60%;
    min-width: 300px;
    margin: 10px;
    
  }
  .businessvcard-image-container{
    display: grid;
    width: auto;
    grid-template-columns: 100%;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    margin-right: 10px;
  
  
  }
  .businessvcard-contents-container{
    display: grid;
    grid-template-rows: auto auto auto;
    grid-gap: 10px !important;
  
  }
  .businessvcard-contents-name-container{
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: left;
  }
  .businessvcard-contents-name{
    display: grid;
    grid-auto-rows: auto auto ;
    
  
  }
  
  .businessvcard-contents-phone{
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: left;
    
  
  }
  /* span{
    font-style: bold;
    font-weight: 900;
    font-size: 10px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  } */
  
  

}

