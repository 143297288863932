.modal_container{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    width: 60%;
    height: 70%;
    /* z-index: 99999999999999; */
    z-index: 1302;
    /* padding: 25px; */
}

.overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color : rgba(0, 0, 0, .5);
    z-index: 1000;
}

.close{
    background-image: linear-gradient(90deg,#f66464,#0632ff 60%,#4eb589) !important;
    padding: 3px !important;
    height: 40 !important;
    width: 40 !important;
   cursor: pointer !important;
   z-index: 9999 !important;
   position: absolute;
   top: -15px;
   right: -15px;
   opacity: 1 !important;
   position: absolute !important;

}

