.listing-users-container{
    display: flex;
    flex-direction: column;
    padding: 15px;
    margin-bottom: 2rem;
}

.listing-users{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: max-content;
    padding: 7px 15px;
    margin-bottom: 1rem;
    background-color: #fff;
    align-items: baseline;
}

.listing-users-header{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 2rem;
    padding: 7px 15px;
    margin-bottom: .2rem;
    align-items: baseline;
    justify-content: flex-start;
}

.listing-users-header > div {
    width: calc(100% - 100px);
}

.listing-users-header > div > span {
    color: #727381;
    font-size: 16px;
}

.listing-users-actions{
    display: flex;
    flex-direction: row;
    text-align: center;
}

.listing-users > div {
    width: calc(100% - 100px);
}



.listing-users-actions > div > img {
    width: 40px;
    height: 40px;
    margin-left: 10px;
    cursor: pointer;
}

.listing-users > div > span {
    color: #727381;
    font-size: 14px;
}

.hide {
    display: none;
}

.listing-users-actions > div{
    margin-left: 1rem;
}
/* responsive  css patches*/
.listing-users-actions > div > span {
    color:#000;
    font-size: 12px;
    white-space: nowrap;
}
/* responsive  end css patches*/ 
