.menu_icons{
    font-size: 1.8rem !important;
    cursor: pointer;
}

.question_icons{
    color:  #1029f7;
    font-weight: bold;
}

.question_top{
    display: flex;
    justify-content: space-between;

}

.questions_container{
    display: flex;
    flex-direction: column;
   
}

.question{
    font-weight: bold;
    font-size: 16px;
    margin: 10px 0px;
}

.question span{
    letter-spacing: 1px;
    padding: 15px 5px;
}

.select_option{
    display: flex;
    flex-direction: column;
    background-color: #fff;
}

.option {
    display: flex;
    justify-content: space-between;
}

.option_answer{
    margin-left: 10px;
    font-weight: 550;
}

.option > input {
    transform: scale(1.4);
    cursor: pointer;
}

.next_container {
    background: rgb(2,0,36);
    background: linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(14,101,219,1) 41%, rgba(0,212,255,1) 100%);
    padding: 10px 15px;
    margin: 25px 0px;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.next_container > span {
    color: #fff;
    text-align: center;
    font-weight: 600;
    letter-spacing: 1px;
    padding: 0px 60px;
}

.next_icon{
    color: #fff;
    margin-left: 1rem;
}

.error_message{
    color: red;
    font-weight: 600;
    font-size: 14px;
    margin-top: 10px;
}

.progress{
    height: 7px;
    width: 100%;
    margin: 8px 0 3px 0px;
    background: #ccc;
    border-radius: 30px;
    position: relative;
}

.progress::after{
    content: "";
    position: absolute;
    height: 100%;
    width: calc(1% * var(--w));
    background: #1029f7;
    border-radius: inherit;
}

.option_percentage{
    margin-left: 4rem;
}

.assign-icon{
    margin: 3rem auto 0 auto;
    margin-bottom: 1rem;
    font-size: 10rem !important;
    color: #1029f7;
}