.leaderBoard-container{
    border: 1px solid #000;
    width: 100%;
    height: 100%; 
}

.leaderBoard-title{
    color: #fff;
    background-color: #4724da;
    font-weight: 600;
    padding: 5px;
}

.leaderboard-body{
    height: 90%;
    display: flex;
    justify-content: space-between;
}

.leaderboard-left{
    height: 100%;
    width: 50%;
    overflow-y: auto;
}
.leaderboard-rankings-list{
    display: flex;
    padding: 15px;
    border-bottom: .5px solid #c9c2c2;
}

.leaderboard-rankings{
    background-color: gray;
    padding: 10px 20px;
    border-radius: 100%;
    height: 50%;
    margin-right: 2rem;
}

.leaderboard-rankings > span{
 color: #fff;
 font-size: 20px;
 font-weight: 600;
}

.leaderboard-rankings-name{
    font-weight: 600;
}

.leaderboard-points{
    color: #3614be;
}

.leaderboard-right{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 50%;
    overflow-y: auto;
    padding: 1.5rem;
    font-family: 'HK Grotesk', sans-serif;
}

.leader-board-first-header{
    margin-top: 0.5rem;
    font-weight: 600;
}