

/* @media (max-device-width: 360px) and (max-device-height: 740px) {
    .Description {
        font-size: large;
    }
}

@media (max-device-width: 1000) and (max-device-height: 740px) {
    .Description {
        font-size: large;
        font-weight: 400;
        padding: 0.5rem;
        width: 170px;
    }
} */

/* @media screen  {
    .Description {
        font-weight: 400;
        padding: 10px;
        width: 600px;
        margin: 0;
    }
  } */

  @media screen and (max-width: 360px)  {
    .Description {
        font-weight: 400;
        padding: 0.5rem;
        text-decoration: none;
        width: 170px;
        height: 500px;
        margin: 0;
        
    }
    .jtitle{
        padding: 1px;
        font-size: 16px;
    }
    .etitile{
        padding: 1px;
        font-size: 16px;
        font-weight: 300;
    }
    .ctitle{
        padding: 1px;
        /* font-size: 120%; */
        font-size: 12.8px ;
        font-weight: bold;

    }
    .img{
        float:left ;
        border-radius: 50%;
    }
  }

  @media screen and (max-width: 420px) {
    .Description {
        font-weight: 400;
        padding: 0.5rem;
        text-decoration: none;
        width: 100%;
        text-align: left;
        height: fit-content;
        margin: 0;
       
    }
    .jtitle{
        padding: 1px;
        font-size: 16px;
    }
    .etitile{
        padding: 1px;
        font-size: 16px;
        font-weight: bold;
    }
    .img{
        border-radius: 50%;
    }
    .ctitle{
        padding: 1px;
        /* font-size: 120%; */
        font-size: 12.8px ;
        font-weight: bold;

    }
  }

  @media screen and (min-width: 500)  {
    .Description {
        font-weight: 400;
        padding: 0.5rem;
        text-decoration: none;
        width: 600px;
        height: fit-content;
        margin: 0;
        
    }
    .jtitle{
        padding: 1px;
        font-size: 16px;
    }
    .etitile{
        padding: 1px;
        font-size: 16px;
    }
    .img{
        float:left ;
        border-radius: 50%;
    }
    .ctitle{
        padding: 1px;
        /* font-size: 120%; */
        font-size: 12.8px ;
        font-weight: bold;

    }
  }
 

  @media screen and (min-width: 820px)  {
    .Description {
        font-weight: 400;
        padding: 0.5rem;
        text-decoration: none;
        width: 600px;
        height: fit-content;
        margin: 0;
        
    }
    .jtitle{
        padding: 1px;
        font-size: 16px;
    }
    .etitile{
        padding: 1px;
        font-size: 16px;
    }
    .img{
        float:left ;
        border-radius: 50%;
    }
    .ctitle{
        padding: 1px;
        /* font-size: 120%; */
        font-size: 12.8px ;
        font-weight: bold;

    }
  }
  @media screen and (min-width: 730px)  {
    .Description {
        font-weight: 400;
        padding: 0.5rem;
        text-decoration: none;
        width: 600px;
        height: fit-content;
        margin: 0;
        
    }
    .jtitle{
        padding: 1px;
        font-size: 16px;
    }
    .ctitle{
        padding: 1px;
        /* font-size: 120%; */
        font-size: 12.8px ;
        font-weight: bold;

        
    }
   
    .etitile{
        padding: 1px;
        font-size: 16px;
    }
    .img{
        float:left ;
        border-radius: 50%;
    }
  }

  @media screen and (min-width: 530px)  {
    .Description {
        font-weight: 400;
        padding: 0.5rem;
        text-decoration: none;
        width: 100%;
        height: fit-content;
        margin: 0;
        
    }
    .jtitle{
        padding: 1px;
        font-size: 16px;
        
    }
    .etitile{
        padding: 1px;
        font-size: 16px;
    }
    .img{
        float:left ;
        border-radius: 50%;
    }
    .ctitle{
        padding: 1px;
        /* font-size: 120%; */
        font-size: 12.8px ;
        font-weight: bold;

    }
  }
  @media screen and (min-width: 1000px) and (max-width: 2560px)  {
    .Description {
        font-weight: 400;
        padding: 0.5rem;
        text-decoration: none;
        width: 100%;
        height: fit-content;
        margin: 0;
        
    }
    .jtitle{
        padding: 1px;
        font-size: 16px;
    }
    .ctitle{
        padding: 1px;
        /* font-size: 120%; */
        font-size: 12.8px;
        font-weight: bold;

    }
    
    .etitile{
        padding: 1px;
        font-size: 16px;
    }
    .img{
        float:left ;
        border-radius: 50%;
    }
  }
 
  @media screen and (min-width: 912px) and (max-width: 1368px)  {
    /* .Description {
        font-weight: 400;
        padding: 0.5rem;
        text-decoration: none;
        width: 100%;
        height: fit-content;
        margin: 0;
        
    }
    .jtitle{
        padding: 1px;
        font-size: x-large;
    }
    .ctitle{
        padding: 1px;
        font-size: 120%;
    } */
    .etitile{
        padding: 1px;
        font-size: 16px;
    }
  }
 