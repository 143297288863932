
@media  screen and (max-width:2560px) {
    .video-overalls{
        flex-direction: column;
        display: flex;
        position: fixed;
        bottom: 250px;
        right: 0px;
        z-index: 99;
        
        
    }
    .audio-overalls{
        flex-direction: column;
        display: flex;
        position: fixed;
        bottom: 300px;
        right: 0px;
        z-index: 99;
       
        
    }
    .video-overalls-lobby{
        flex-direction: column;
        display: flex;
        position: fixed;
        bottom: 350px;
        right: 0px;
        z-index: 99;

    }
    .audio-overalls-lobby{
        flex-direction: column;
        display: flex;
        position: fixed;
        bottom: 340px;
        right: 0px;
        z-index: 99;
       

    }

    .rotate-overalls{
        flex-direction: column;
        display: flex;
        position: fixed;
        bottom:350px;
        right: 22px;
        z-index: 99;
    }
}
@media  screen and (max-width:1440px) {
    .video-overalls{
        flex-direction: column;
        display: flex;
        position: fixed;
        bottom: 250px;
        right: 0px;
        z-index: 99;
        
        
    }
    .audio-overalls{
        flex-direction: column;
        display: flex;
        position: fixed;
        bottom: 300px;
        right: 0px;
        z-index: 99 ;
       
        
    }
    .video-overalls-lobby{
        flex-direction: column;
        display: flex;
        position: fixed;
        bottom: 360px;
        right: 0px;
        z-index: 99;

    }
    .audio-overalls-lobby{
        flex-direction: column;
        display: flex;
        position: fixed;
        bottom: 350px;
        right: 0px;
        z-index: 99;
       

    }
    .rotate-overalls{
        flex-direction: column;
        display: flex;
        position: fixed;
        bottom:350px;
        right: 22px;
        z-index: 99;
    }
}
@media  screen and (max-width:1024px) {
    .video-overalls{
        flex-direction: column;
    display: flex;
    position: fixed;
    bottom: 110px;
    right: 0px;
    z-index: 99 !important;
        
    }
    .audio-overalls{
        flex-direction: column;
        display: flex;
        position: fixed;
        bottom: 180px;
        right: 0px;
        z-index: 99;
    
        
    }
    .video-overalls-lobby{
        flex-direction: column;
        display: flex;
        position: fixed;
        bottom: 210px;
        right: 0px;
        z-index: 99;

    }
    .audio-overalls-lobby{
        flex-direction: column;
        display: flex;
        position: fixed;
        bottom: 300px;
        right: 0px;
        z-index: 99;
       

    }
    .rotate-overalls{
        flex-direction: column;
        display: flex;
        position: fixed;
        bottom: 250px;
        right: 22px;
        z-index: 99;
    }
    
}
@media  screen and (max-width:920px) {

    .video-overalls-lobby{
        flex-direction: column;
        display: flex;
        position: fixed;
        bottom: 210px;
        right: 0px;
        z-index: 99;

    }
    .audio-overalls-lobby{
        flex-direction: column;
        display: flex;
        position: fixed;
        bottom: 200px;
        right: 0px;
        z-index: 99;
       

    }

    
}

@media  screen and (max-width:768px) {
    .video-overalls{
        flex-direction: column;
    display: flex;
    position: fixed;
    bottom: 75px;
    right: 0px;
    z-index: 99;
        
    }
    .audio-overalls{
        flex-direction: column;
        display: flex;
        position: fixed;
        bottom: 120px;
        right: 0px;
        z-index: 99;
    
        
    }
    .video-overalls-lobby{
        flex-direction: column;
        display: flex;
        position: fixed;
        bottom: 240px;
        right: 0px;
        z-index: 99;

    }
    .audio-overalls-lobby{
        flex-direction: column;
        display: flex;
        position: fixed;
        bottom: 230px;
        right: 0px;
        z-index: 99;
       

    }
    .rotate-overalls{
        flex-direction: column;
        display: flex;
        position: fixed;
        bottom: 170px;
        right: 22px;
        z-index: 99;
    }
    
}
@media  screen and (max-width:425px) {
    .video-overalls{
        flex-direction: column;
        display: flex;
        position: fixed;
        bottom: 200;
        right: 0;
        z-index: 99;
        
    }
    .audio-overalls{
        flex-direction: "column";
        display: flex;
        position: fixed;
        bottom: 250;
        right: 0;
        z-index: 99,
        
    }
    .video-overalls-lobby{
        flex-direction: column;
        display: flex;
        position: fixed;
        bottom: 250px;
        right: 0px;
        z-index: 99;

    }
    .audio-overalls-lobby{
        flex-direction: column;
        display: flex;
        position: fixed;
        bottom: 300px;
        right: 0px;
        z-index: 99;
       

    }
    .rotate-overalls{
        flex-direction: column;
        display: flex;
        position: fixed;
        bottom:250px;
        right: 50px;
        z-index: 99;
    }
    
}
@media  screen and (max-width:320px) {
    .video-overalls{
        flex-direction: column;
        display: flex;
        position: fixed;
        bottom: 200;
        right: 0;
        z-index: 99;
        
    }
    .audio-overalls{
        flex-direction: "column";
        display: flex;
        position: fixed;
        bottom: 250;
        right: 0;
        z-index: 99,
        
    }
    .video-overalls-lobby{
        flex-direction: column;
        display: flex;
        position: fixed;
        bottom: 250px;
        right: 0px;
        z-index: 99;

    }
    .audio-overalls-lobby{
        flex-direction: column;
        display: flex;
        position: fixed;
        bottom: 300px;
        right: 0px;
        z-index: 99;
       

    }
    .rotate-overalls{
        flex-direction: column;
        display: flex;
        position: fixed;
        bottom:250px;
        right: 50px;
        z-index: 99;
    }
    
}
@media  screen and (max-width:660px) {

    .video-overalls-lobby{
        flex-direction: column;
        display: flex;
        position: fixed;
        bottom: 100px;
        right: 0px;
        z-index: 99;

    }
    .audio-overalls-lobby{
        flex-direction: column;
        display: flex;
        position: fixed;
        bottom: 90px;
        right: 0px;
        z-index: 99;
       

    }

    
}

@media screen and (max-width : 999px){
    .video-overalls-lobby{
        bottom: 62%;
    }
    .audio-overalls-lobby{
        bottom: 60%;
    }
}