
    .register-overall{
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 35% auto  ;
        font-family:'Roboto';
     }
     .register-eventinfo{
         background-color: #1500fa;
         width: auto;
         height: 100%;
         margin: 0;
     }
     .register-content{
         background-color: white !important;
         width: 100%;
         height: 100%;
         display: flex;
         position: relative;
         flex-direction: column;
         justify-content: center;
         font-size: 15px;
         padding-bottom: 30px;
         
     }
     .register-content-sub{
         display: flex;
         flex-direction: column;
         max-width: 500px;
         height: auto;
     }
     .register-content-main{
         display: flex;
         flex-grow: 1;
         margin: 0;
         padding: 0;
         align-items: center;
         justify-content: center;
     }
     .register-content-maintext-titletext{
         font-size: 25px; 
         font-weight: 600; 
         justify-content: left; 
         text-align: left;  
         color: black; 
         letter-spacing: 1px; 
         max-width: auto;
     }
     .register-content-maintext-desctext{
         text-align: left; 
         justify-content: center; 
         font-size: 15px; 
         color: lightslategrey; 
         letter-spacing: 1px; 
         font-weight: 300; 
         max-width: auto;
     }
     .register-content-maincontent{
         display: flex;
         flex-direction: row;
         flex-wrap: wrap;
         width: 100%;
         justify-content:left;
         width: auto;
     }
     .register-content-maincontent-wrapper{
       display: flex;
       flex-direction: column;
        width: 50%;
        padding-right: 5px;
       align-items: center;
       justify-content: center;

     }
     .register-content-inputname{
         text-align: left;
         width: 100%;
     }
     .register-content-inputbox{
         display: flex ;
         width: 100%;
         position: relative;
         font-size: 16px;
         padding: 5px 10px;
         border-style: solid;
         background:none;
         height: 40px;
         
     }
     .register-pageswitch-wrapper{
         text-align:left; 
         justify-content:left ;
          display:grid ;
         grid-template-rows: auto ; 
         grid-template-columns:auto auto ; 
         width: 100% ;
          padding-top: 2px ;
          padding-bottom: 25px;
          font-size: 13px;
     }
     .register-content-maintext-title{
         display: flex;
         flex-direction: row;
         justify-content: left;
         padding-bottom: px; 
     }
     .register-content-logo{
         display: flex;
         width: 100%;
         padding-top: 30px;
         padding-left: 20px;
         padding-right: 20px;
         align-items: flex-end;
         justify-content: flex-end;
     }
     .register-content-bottomtext-button{
         display: flex; 
         width: 50%; 
         justify-content:left; 
         align-items:left;
         padding-top: 5px;
         padding-bottom: 5px;
     }
     .register-content-bottomtext-button-wrapper{
         display: flex;
        background-color: rgb(21, 0, 250) ; 
         width: 100% ; 
         border-radius: 1px;
         padding: 5px ; 
         justify-content:center; 
         cursor: pointer;
     }
     .register-content-bottomtext-terms{
         display: flex;
         justify-content: left;
         width:100%; 
     }
     .register-content-bottomtext-terms-checkbox{
         display: flex;
         height: 20px;  
     }
     .register-content-bottomtext-terms-words{
         font-weight:400; 
     }
     .register-content-maintext-desc{
         display: flex;
         justify-content: left;
         padding-bottom: 10px; 
     }
     .register-content-errors{
         display: flex;
         justify-content: left;
         width: 100%;
         height: 15px;
     }
     .register-content-bottomtext{
         display: flex;
         flex-direction: column;
         padding-top: 20px;

     }
    @media (max-width:3840px) and (min-width:1920px){
        .register-content-maincontent-wrapper{
            padding-right: 20px;
        }
        .register-content-maintext-desc{
            padding-bottom: 20px;
        }
        

    }
    

    @media (max-width:1023px) and (min-width:424px){
         .register-overall{
             width: 100%;
             height: 100%;
             display: grid;
             grid-template-columns: auto;
         }
         .register-eventinfo{
            display: none;
         }


    }
    @media (max-width: 425px) and (min-width:280px){    
         .register-overall{
             width: 100%;
             height: 100%;
             display: grid;
             grid-template-columns:  auto;
        }
        .register-eventinfo{
            display: none;
        }
        .register-content{
            background-color: white !important;
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-size: 15px;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        }
        .register-content-sub{
            display: flex;
            flex-direction: column;
            max-width: 300px;
            height: auto; 
        }
        .register-content-main{
            display: flex;
            flex-grow: 1;
            margin: 0;
            padding: 0;
            align-items: center;
            justify-content: center;
        }
        .register-content-maincontent-wrapper{
            display: flex;
            flex-direction: column;
             width: 100%;
             padding-right: 5px;
            align-items: center;
            justify-content: center;
          }
          .register-content-bottomtext-button{
            display: flex; 
            width: 100%; 
            justify-content:left; 
            align-items:left;
            padding:5px 0px;
        }
        .register-content-bottomtext-button-wrapper{
            display: flex;
            background-color: rgb(21, 0, 250) ; 
            width: 100%; 
            border-radius: 1px;
            padding: 5px ; 
            justify-content:center; 
            cursor: pointer;
            
        }
        .register-content-maintext-desc{
            display: flex;
            justify-content: left;
            padding: 5px 0px; 
        }
        
          
 }
    
     
