@import url("https://cdn.jsdelivr.net/npm/@xz/fonts@1/serve/hk-grotesk.min.css");



  @media only screen and (max-width: 2560px){
    .qr-products-overall{
        display: grid;
        width: 100%;
        height: 100%; 
    }
    .qr-products-header{
        display: grid;
        width: 100%;
        height: 80%;
        margin-top: 3rem;
        font-weight: 700;
        font-family: 'HK Grotesk', sans-serif;
        font-size: 2rem;
        align-items: center;
        justify-content: center;
    }
    .qr-products-list-content{
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow-y:auto;
        -ms-overflow-style: none;  
        scrollbar-width: none;
        justify-content: space-between;
    }
    .qr-products-list-content::-webkit-scrollbar{
        display: none;
    }
    
    .qr-products-single-common{
        display: flex;
        width: 100%;
        height: 80px;
        padding-left: 3rem;
        padding-right: 3rem;
        justify-content: space-between;
        flex-direction: row;
    }
    .qr-products-title{
        display: flex;
        width: max-content;
        justify-content: center;
        align-items: center;
    }
    .qr-products-actions{
        display: flex;
        width: max-content;
        align-items: center;
    }
    .qr-products-common{
        display: grid;
        width:max-content;
        height: max-content;
        padding: 1rem;
        align-items: center;
    }
    .qr-products-action-button-common{
        display: grid;
        width:max-content;
        height: max-content;
        color: aliceblue;
        padding: 1rem;
        background-color: black;
        border-radius: 5px;
        align-items: center;
        cursor: pointer;
        justify-content: center;
        box-shadow: 0px 3px 7px 3px rgb(99, 99, 99);
        
        
    }
    .qr-products-action-button-common:hover{
        background-color: linear-gradient(to right, #000000 , #29282a, #343437, #434346, #525356, #626366, #7b7b7e) !important;
    }
    .qr-product-detail-info{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 15rem;
    }
    .qr-product-detail-title{
        display: flex;
        align-items: center;
        font-weight: 500;
        font-family: 'HK Grotesk', sans-serif;
        font-size: 1.25rem;
    
    
    }
    .qr-product-detail-code{
        display: flex;
        align-items: center;
        font-weight: 500;
        font-family: 'HK Grotesk', sans-serif;
        font-size: 1rem;
    
    }
    .qr-product-detail-actions{
        display: flex;
        flex-direction: column;
        width: auto;
        height: 6rem;
        justify-content: space-between;
        
    
    }
    .qr-product-detail-action-button-common{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: #000000;
        color: white;
        padding: 0.5rem;
        font-weight: 500;
        font-family: 'HK Grotesk', sans-serif;
        font-size: 1rem;
        border-radius: 5px;
        cursor: pointer;
    
    
    }
    .qr-product-detail-image{
        display: flex;
        /* width: 25rem; */
        height: 25rem;
        
        
        justify-content: center;
    
    }
    .qr-product-detail-common-content{
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: row;
       
        justify-content: space-around;
    
    }
    .qr-product-detail-common-content-wrapper{
        display: flex;
        width: 100%;
        height:100%;
        flex-direction: column;
        align-items: center;
    
    
    }
    .qr-product-detail-common-overall{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
       
    
    }
    .qr-product-detail-full{
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;
    }
    
    .qr-product-detail-nav{
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 1rem;
        align-items: center;
    
    }
    .qr-product-detail-header{
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        font-family: 'HK Grotesk', sans-serif;
        font-size: 1.8rem;
    }
    .qr-common-icon{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1.5rem;
        background-color: #000000;
        color: white;
        width: 4.5rem;
        cursor: pointer;
        
        
    }
    .qr-common-icon:hover{
        transform: scale(0.9);
        transition: all .3s ease-in-out;
        border-radius: 10px;
        background-color: none;
    
    }
    .qr-common-icon-activate{
        display: flex;
        flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.4rem;
    background-color: #000000;
    color: white;
    width: 100%;
    cursor: pointer;
        transform: scale(1.1);
        border: 1px solid white;
        border-radius: 10px;
    
    }
    .qr-common-icon-activate span{
        font-size:medium;

    }
    .qr-product-detail-common-tab-single{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 1.5rem;
        width: 10%;
        height: max-content;
        box-shadow: 0px 5px 10px 1px rgb(99, 99, 99);
        background-color: #000000;
    }
    .qr-product-detail-common-right-end{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 25rem;
        width: 4.5rem;
        
    }
    .qr-product-detail-description{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        font-family: 'HK Grotesk', sans-serif;
        font-size: 0.9rem;
    
    }
    .qr-product-image-overall{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 60rem;
        
    }
    .qr-product-image-center{
        display: flex;
        width: 35rem;
        height: 25rem;
        justify-content: center;
    }
    .qr-product-image-common-button{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 4rem;
        border-radius: 50%;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        height: 4rem;
        box-shadow: 0px 3px 7px 3px rgb(99, 99, 99);
        cursor: pointer;
        background-color: #000000;
        color: white;
    }
    
    .qr-product-video-overall{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 60rem;
    
    }
    
    .qr-product-video-common-button{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 4rem;
        border-radius: 50%;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        height: 4rem;
        box-shadow: 0px 3px 7px 3px rgb(99, 99, 99);
        cursor: pointer;
        background-color: #000000;
        color: white;
    
    }
    .qr-product-video-center{
        display: flex;
        width: 35rem;
        height: 25rem;
        justify-content: center;
    
    }
    /* .qr-product-detail-common-content-overall{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        justify-content: center;
    }
    .qr-product-detail-common-content-title{
        display: flex;
        align-items: center;
        font-weight: 700;
        font-family: 'HK Grotesk', sans-serif;
        font-size: 1.5rem;
    
    } */
    
    .qr-document-table-overall{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 60rem;
        height:25rem;
    }
    .qr-document-contentlist{
        display: flex;
        flex-direction: column;
        width:100%;
        height:100%;
        overflow-y: auto;
        -ms-overflow-style: none;  
        scrollbar-width: none;
    }
    .qr-document-contentlist::-webkit-scrollbar{
        display: none;
    }
    .qr-document-common{
        display: flex;
        flex-direction: row;
        width: 100%;
        height: auto;
        margin-bottom: 1rem;
        
    
    }
    .qr-document-title{
        display: flex;
        align-items: center;
        justify-content: left;
        width: 33%;
    
    }
    .document-title{
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-family: 'HK Grotesk', sans-serif;
        font-size: 1.2rem;
    
    }
    .qr-document-description{
        display: flex;
        align-items: center;
        justify-content: left;
        width: 33%;
    
    
    }
    .document-description{
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-family: 'HK Grotesk', sans-serif;
        font-size: 1rem;
    
    }
    .qr-document-action{
        display: flex;
        align-items: center;
        justify-content: right;
        width: 33%;
    
    }
    .qr-document-download-btn{
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #000000;
        width: 2rem;
        height:2rem;
        color: white;
        cursor:pointer;
    
    }
    .qr-model-view-overall{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 60rem;
        height:25rem;
    
    }
    .qr-model-view-switch-overall{
        display: flex;
        flex-direction: row;
        background-color: black;
        width: 10%;
        height: 100%;
    
    
    }
    .qr-model-view-preview-overall{
        display: flex;
        flex-direction: row;
        width: 90%;
        
      
    
    }
    .qr-model-view-option-container{
        display:grid;
        
        align-items: center;
        height:35%; 
        
        border-radius: 10px;
        grid-template-rows: auto auto ;
    }
    .qr-model-view-switch-buttondiv{
        align-items:center;
      justify-content:center;
      position: inherit;
      display:grid;
      grid-template-columns: auto auto;
      grid-column-gap: 10px;
      padding: 5px;
      cursor:pointer; 
    }
    .qr-model-view-toggle-wrapper{
        position: relative;
        
        display: grid;
        width: 50px;
        height: 25px;
        justify-content: center;
        justify-content: center;
        margin-bottom: 0;
        
    }
    .qr-model-view-toggle-wrapper input{
        opacity: 0;
        width: 0;
        height: 0;
    }
    .qr-model-view-toggle-slider{
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: grey;
        transition: 0.4s;
        border-radius: 20px;
    }
    .qr-model-view-toggle-slider::before{
        content: "";
        position: absolute;
        background-color: white;
        width: 15px;
        height: 15px;
        left: 5px;
        bottom: 5px;
        transition: 0.4s;
        border-radius: 50%;
    }
    
    .qr-model-view-toggle-wrapper input:checked + .qr-model-view-toggle-slider::before{
        transform: translateX(25px) 
      }
      .qr-model-view-toggle-wrapper input:checked + .qr-model-view-toggle-slider{
        background-color: rgb(83, 83, 226); 
      }

  }
  @media only screen and (max-width: 1440px){
    .qr-products-overall{
        display: grid;
        width: 100%;
        height: 100%; 
    }
    .qr-products-header{
        display: grid;
        width: 100%;
        height: 80%;
        margin-top: 3rem;
        font-weight: 700;
        font-family: 'HK Grotesk', sans-serif;
        font-size: 2rem;
        align-items: center;
        justify-content: center;
    }
    .qr-products-list-content{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 1.5rem;
        overflow-y:auto;
        -ms-overflow-style: none;  
        scrollbar-width: none;
        justify-content: space-between;
    }
    .qr-products-list-content::-webkit-scrollbar{
        display: none;
    }
    
    .qr-products-single-common{
        display: flex;
        width: 100%;
        height: 80px;
        padding-left: 3rem;
        padding-right: 3rem;
        justify-content: space-between;
        flex-direction: row;
    }
    .qr-products-title{
        display: flex;
        width: max-content;
        justify-content: center;
        align-items: center;
    }
    .qr-products-actions{
        display: flex;
        width: max-content;
        align-items: center;
    }
    .qr-products-common{
        display: grid;
        width:max-content;
        height: max-content;
        padding: 1rem;
        align-items: center;
    }
    .qr-products-action-button-common{
        display: grid;
        width:max-content;
        height: max-content;
        color: aliceblue;
        padding: 1rem;
        background-color: black;
        border-radius: 5px;
        align-items: center;
        cursor: pointer;
        justify-content: center;
        box-shadow: 0px 3px 7px 3px rgb(99, 99, 99);
        
        
    }
    .qr-products-action-button-common:hover{
        background-color: linear-gradient(to right, #000000 , #29282a, #343437, #434346, #525356, #626366, #7b7b7e) !important;
    }
    .qr-product-detail-info{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 15rem;
    }
    .qr-product-detail-title{
        display: flex;
        align-items: center;
        font-weight: 500;
        font-family: 'HK Grotesk', sans-serif;
        font-size: 1.25rem;
    
    
    }
    .qr-product-detail-code{
        display: flex;
        align-items: center;
        font-weight: 500;
        font-family: 'HK Grotesk', sans-serif;
        font-size: 1rem;
    
    }
    .qr-product-detail-actions{
        display: flex;
        flex-direction: column;
        width: auto;
        height: auto;
        justify-content: space-between;
        
    
    }
    
    .qr-product-detail-action-button-common{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: #000000;
        color: white;
        padding: 0.5rem;
        margin-bottom: 0.3rem;
        font-weight: 500;
        font-family: 'HK Grotesk', sans-serif;
        font-size: 1rem;
        border-radius: 5px;
        cursor: pointer;
        
    
    
    }
    .qr-product-detail-image{
        display: flex;
        /* width: 25rem; */
        height: 25rem;
        
        
        justify-content: center;
    
    }
    .qr-product-detail-common-content{
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: row;
       
        justify-content: space-around;
    
    }
    .qr-product-detail-common-content-wrapper{
        display: flex;
        width: 100%;
        height:100%;
        flex-direction: column;
        align-items: center;
    
    
    }
    .qr-product-detail-common-overall{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
       
    
    }
    .qr-product-detail-full{
          display: grid;
          width: 100%;
          height: 90%;
    }
    
    .qr-product-detail-nav{
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 1rem;
        align-items: center;
        /* margin-top: 3rem; */
    }
    .qr-product-detail-header{
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        font-family: 'HK Grotesk', sans-serif;
        font-size: 1.8rem;
    }
    .qr-common-icon{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        background-color: #000000;
        color: white;
        width: max-content;
        cursor: pointer;
    }
    .qr-common-icon span{
        font-size: small;
    }
    .qr-common-icon:hover{
        transform: scale(0.9);
        transition: all .3s ease-in-out;
        border-radius: 10px;
        background-color:none ;
    
    }
    .qr-common-icon-activate{
        display: flex;
        flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.4rem;
    background-color: #000000;
    color: white;
    width: 100%;
    cursor: pointer;
        transform: scale(1.1);
        border: 1px solid white;
        border-radius: 10px;
    
    }
    .qr-common-icon-activate span{
        font-size:medium;

    }
    .qr-product-detail-common-tab-single{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 1.5rem;
        width: 10%;
        height: max-content;
        box-shadow: 0px 5px 10px 1px rgb(99, 99, 99);
        background-color: #000000;
    }
    .qr-product-detail-common-right-end{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 25rem;
        width: 4.5rem;
        
    }
    .qr-product-detail-description{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        font-family: 'HK Grotesk', sans-serif;
        font-size: 0.9rem;
    
    }
    .qr-product-image-overall{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 60rem;
        
    }
    .qr-product-image-center{
        display: flex;
        width: 35rem;
        height: 25rem;
        justify-content: center;
    }
    .qr-product-image-common-button{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 4rem;
        border-radius: 50%;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        height: 4rem;
        box-shadow: 0px 3px 7px 3px rgb(99, 99, 99);
        cursor: pointer;
        background-color: #000000;
        color: white;
    }
    
    .qr-product-video-overall{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 60rem;
    
    }
    
    .qr-product-video-common-button{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 4rem;
        border-radius: 50%;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        height: 4rem;
        box-shadow: 0px 3px 7px 3px rgb(99, 99, 99);
        cursor: pointer;
        background-color: #000000;
        color: white;
    
    }
    .qr-product-video-center{
        display: flex;
        width: 35rem;
        height: 25rem;
        justify-content: center;
    
    }
    /* .qr-product-detail-common-content-overall{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        justify-content: center;
    }
    .qr-product-detail-common-content-title{
        display: flex;
        align-items: center;
        font-weight: 700;
        font-family: 'HK Grotesk', sans-serif;
        font-size: 1.5rem;
    
    } */
    
    .qr-document-table-overall{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 60rem;
        height:25rem;
    }
    .qr-document-contentlist{
        display: flex;
        flex-direction: column;
        width:100%;
        height:100%;
        overflow-y: auto;
        -ms-overflow-style: none;  
        scrollbar-width: none;
    }
    .qr-document-contentlist::-webkit-scrollbar{
        display: none;
    }
    .qr-document-common{
        display: flex;
        flex-direction: row;
        width: 100%;
        height: auto;
        margin-bottom: 1rem;
        
    
    }
    .qr-document-title{
        display: flex;
        align-items: center;
        justify-content: left;
        width: 33%;
    
    }
    .document-title{
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-family: 'HK Grotesk', sans-serif;
        font-size: 1.2rem;
    
    }
    .qr-document-description{
        display: flex;
        align-items: center;
        justify-content: left;
        width: 33%;
    
    
    }
    .document-description{
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-family: 'HK Grotesk', sans-serif;
        font-size: 1rem;
    
    }
    .qr-document-action{
        display: flex;
        align-items: center;
        justify-content: right;
        width: 33%;
    
    }
    .qr-document-download-btn{
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #000000;
        width: 2rem;
        height:2rem;
        color: white;
        cursor:pointer;
    
    }
    .qr-model-view-overall{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: 10%;
    
    }
    .qr-model-view-switch-overall{
        display: flex;
        flex-direction: row;
        background-color: black;
        width: 100%;
        height: 100%;
    
    
    }
    .qr-model-view-preview-overall{
        display: flex;
        flex-direction: row;
        width: 100%;
        border: 1px solid black;
        
      
    
    }
    .qr-model-view-option-container{
        display:grid;
        
        align-items: center;
        height:35%; 
        
        border-radius: 10px;
        grid-template-columns: auto auto ;
    }
    .qr-model-view-switch-buttondiv{
        align-items:center;
      justify-content:center;
      position: inherit;
      display:grid;
      grid-template-columns: auto auto;
      grid-column-gap: 10px;
      padding: 5px;
      cursor:pointer; 
    }
    .qr-model-view-toggle-wrapper{
        position: relative;
        
        display: grid;
        width: 50px;
        height: 25px;
        justify-content: center;
        justify-content: center;
        margin-bottom: 0;
        
    }
    .qr-model-view-toggle-wrapper input{
        opacity: 0;
        width: 0;
        height: 0;
    }
    .qr-model-view-toggle-slider{
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: grey;
        transition: 0.4s;
        border-radius: 20px;
    }
    .qr-model-view-toggle-slider::before{
        content: "";
        position: absolute;
        background-color: white;
        width: 15px;
        height: 15px;
        left: 5px;
        bottom: 5px;
        transition: 0.4s;
        border-radius: 50%;
    }
    
    .qr-model-view-toggle-wrapper input:checked + .qr-model-view-toggle-slider::before{
        transform: translateX(25px) 
      }
      .qr-model-view-toggle-wrapper input:checked + .qr-model-view-toggle-slider{
        background-color: rgb(83, 83, 226); 
      }

}
@media only screen and (max-width: 1024px){
    .qr-products-overall{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%; 
    }
    .qr-products-header{
        display: flex;
        width: 100%;
        height: auto;
        font-weight: 700;
        margin:0;
        font-family: 'HK Grotesk', sans-serif;
        font-size: 1.2rem;
        align-items: center;
        justify-content: center;
    }
    .qr-products-list-content{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 0.1rem;
        overflow-y:auto;
        -ms-overflow-style: none;  
        scrollbar-width: none;
        justify-content: flex-start;
    }
    .qr-products-list-content::-webkit-scrollbar{
        display: none;
    }
    
    .qr-products-single-common{
        display: flex;
        width: 100%;
        /* height: 80px; */
        padding-left: 2rem;
        padding-right: 2rem;
        justify-content: space-between;
        flex-direction: row;
    }
    .qr-products-title{
        display: flex;
        width: max-content;
        justify-content: center;
        align-items: center;
    }
    .qr-products-title h5{
        font-size: 0.8rem;
    }
    .qr-products-actions{
        display: flex;
        width: max-content;
        align-items: center;
    }
    .qr-products-common{
        display: grid;
        width:max-content;
        height: max-content;
        padding: 1rem;
        align-items: center;
    }
    .qr-products-action-button-common{
        display: grid;
        width:max-content;
        height: max-content;
        color: aliceblue;
        padding: 0.3rem;
        background-color: black;
        border-radius: 5px;
        align-items: center;
        cursor: pointer;
        justify-content: center;
        box-shadow: 0px 1px 3px 2px rgb(141 140 140);
        font-size: 0.7rem;
        
        
    }
    .qr-products-action-button-common:hover{
        background-color: linear-gradient(to right, #000000 , #29282a, #343437, #434346, #525356, #626366, #7b7b7e) !important;
    }
    .qr-product-detail-info{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
    }
    .qr-product-detail-title{
        display: flex;
    align-items: center;
    font-weight: 500;
    font-family: 'HK Grotesk', sans-serif;
    font-size: 0.9rem;
    margin-bottom: 0.2rem;
    
    
    }
    .qr-product-detail-code{
        display: flex;
        align-items: center;
        font-weight: 500;
        font-family: 'HK Grotesk', sans-serif;
        font-size: 0.8rem;
        margin-bottom: 0.2rem;
    
    }
    .qr-product-detail-actions{
        display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    justify-content: space-between;
    margin-bottom: 0.2rem;
        
    
    }
    .qr-product-detail-action-button-common{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: #000000;
        color: white;
        padding: 0.3rem;
        font-weight: 500;
        font-family: 'HK Grotesk', sans-serif;
        font-size: 0.7rem;
        border-radius: 5px;
        margin-right: 0.3rem;
        width: 50%;
        cursor: pointer;
        
    
    
    }
    .qr-product-detail-image{
        display: flex;
    width: 15rem;
    height: 8rem;
    justify-content: flex-start;
    
    }
    .qr-product-detail-common-content{
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        justify-content: flex-start;
        
        
    
    }
    .qr-product-detail-common-content-wrapper{
        display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow-y: auto;
    
    
    }
    .qr-product-detail-common-overall{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        overflow-y: hidden;
       
    
    }
    .qr-product-detail-full{
          display: grid;
          grid-template-rows: 20% 80%;
          width: 100%;
          height: 100%;
    }
    
    .qr-product-detail-nav{
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 50px;
        padding: 1rem;
        align-items: center;
        
    }
    .qr-product-detail-header{
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        font-family: 'HK Grotesk', sans-serif;
        font-size: 1.2rem;
    }
    .qr-common-icon{
        display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.4rem;
    background-color: #000000;
    color: white;
    width: 100%;
    cursor: pointer;
        
        
    }
    .qr-common-icon span{
        font-size:xx-small;
    }
    .qr-common-icon:hover{
        transform: scale(0.9);
        transition: all .3s ease-in-out;
        border-radius: 10px;
        background-color: none;
    
    }
    .qr-common-icon-activate{
        display: flex;
        flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.4rem;
    background-color: #000000;
    color: white;
    width: 100%;
    cursor: pointer;
        transform: scale(1.1);
        border: 1px solid white;
        border-radius: 10px;
    
    }
    .qr-common-icon-activate span{
        font-size:x-small;

    }
    .qr-product-detail-common-tab-single{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 0.7rem;
        width: 10%;
        height: max-content;
        box-shadow: 0px 5px 10px 1px rgb(99, 99, 99);
        background-color: #000000;
    }
    .qr-product-detail-common-right-end{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
        width: 4.5rem;
        
    }
    .qr-product-detail-description{
        display: flex;
        
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-weight: 500;
        font-family: 'HK Grotesk', sans-serif;
        font-size: 0.8rem;
    
    }
    .qr-product-image-overall{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 400px;
        
    }

    .qr-product-image-center{
        display: flex;
        width: 300px;
        height: 300px;
        justify-content: center;
    }
    .qr-product-image-center img{
        width: 300px;
        height:300px;
    }
    .qr-product-image-common-button{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        border-radius: 50%;
        margin-left: 1px;
        margin-right: 1px;
        height: 50px;
        box-shadow: 0px 3px 7px 3px rgb(99, 99, 99);
        cursor: pointer;
        background-color: #000000;
        color: white;
    }
    
    .qr-product-video-overall{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 400px;
    
    }
    
    .qr-product-video-common-button{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        border-radius: 50%;
        margin-left: 0.1rem;
        margin-right: 0.1rem;
        height: 50px;
        box-shadow: 0px 3px 7px 3px rgb(99, 99, 99);
        cursor: pointer;
        background-color: #000000;
        color: white;
    
    }
    .qr-product-video-center{
        display: flex;
        width: 300px;
        height: 300px;
        /* height: 25rem; */
        justify-content: center;
    
    }
    /* .qr-product-detail-common-content-overall{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        justify-content: center;
    }
    .qr-product-detail-common-content-title{
        display: flex;
        align-items: center;
        font-weight: 700;
        font-family: 'HK Grotesk', sans-serif;
        font-size: 1.5rem;
    
    } */
    
    .qr-document-table-overall{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 20rem;
        height:20rem;
    }
    .qr-document-contentlist{
        display: flex;
        flex-direction: column;
        width:100%;
        height:100%;
        overflow-y: auto;
        -ms-overflow-style: none;  
        scrollbar-width: none;
    }
    .qr-document-contentlist::-webkit-scrollbar{
        display: none;
    }
    .qr-document-common{
        display: flex;
        flex-direction: row;
        width: 100%;
        height: auto;
        margin-bottom: 1rem;
        
    
    }
    .qr-document-title{
        display: flex;
        align-items: center;
        justify-content: left;
        width: 33%;
        margin-right: 0.5rem;
    
    }
    .document-title{
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-family: 'HK Grotesk', sans-serif;
        font-size: 0.8rem;
    
    }
    .qr-document-description{
        display: flex;
        align-items: center;
        justify-content: left;
        width: 33%;
        margin-right: 0.5rem;
    
    
    }
    .document-description{
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-family: 'HK Grotesk', sans-serif;
        font-size: 0.7rem;
    
    }
    .qr-document-action{
        display: flex;
        align-items: center;
        justify-content: right;
        width: 33%;
    
    }
    .qr-document-download-btn{
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #000000;
        width: 2rem;
        height:2rem;
        color: white;
        cursor:pointer;
    
    }
    .qr-model-view-overall{
        display: flex;
        flex-direction: column;
        /* align-items: center; */
        justify-content: flex-start;
        width: 100%;
        height: 300px;
       
    
    }
    .qr-model-view-switch-overall{
            display: flex;
            flex-direction: row;
            background-color: black;
            width: 100%;
            height: 15%;

    
    
    }
    .qr-model-view-preview-overall{
        display: flex;
        flex-direction: row;
        width: 100%;
        height:85%;
        
      
    
    }
    .qr-model-view-option-container{
        display:grid;
        
        align-items: center;
        height:100%; 
        
        border-radius: 10px;
        grid-template-columns: auto auto ;
    }
    .qr-model-view-switch-buttondiv{
        align-items:center;
      justify-content:center;
      position: inherit;
      display:grid;
      grid-template-columns: auto auto;
      grid-column-gap: 10px;
      padding: 5px;
      cursor:pointer; 
    }
    .qr-model-view-toggle-wrapper{
        position: relative;
        
        display: grid;
        width: 50px;
        height: 25px;
        justify-content: center;
        justify-content: center;
        margin-bottom: 0;
        
    }
    .qr-model-view-toggle-wrapper input{
        opacity: 0;
        width: 0;
        height: 0;
    }
    .qr-model-view-toggle-slider{
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: grey;
        transition: 0.4s;
        border-radius: 20px;
    }
    .qr-model-view-toggle-slider::before{
        content: "";
        position: absolute;
        background-color: white;
        width: 15px;
        height: 15px;
        left: 5px;
        bottom: 5px;
        transition: 0.4s;
        border-radius: 50%;
    }
    
    .qr-model-view-toggle-wrapper input:checked + .qr-model-view-toggle-slider::before{
        transform: translateX(25px) 
      }
      .qr-model-view-toggle-wrapper input:checked + .qr-model-view-toggle-slider{
        background-color: rgb(137 112 255); 
      }


}
@media only screen and (max-width: 768px){
    .qr-products-overall{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%; 
    }
    .qr-products-header{
        display: flex;
        width: 100%;
        height: auto;
        font-weight: 700;
        margin:0;
        font-family: 'HK Grotesk', sans-serif;
        font-size: 1.2rem;
        align-items: center;
        justify-content: center;
    }
    .qr-products-list-content{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 0.1rem;
        overflow-y:auto;
        -ms-overflow-style: none;  
        scrollbar-width: none;
        justify-content: space-between;
    }
    .qr-products-list-content::-webkit-scrollbar{
        display: none;
    }
    
    .qr-products-single-common{
        display: flex;
        width: 100%;
        /* height: 80px; */
        padding-left: 2rem;
        padding-right: 2rem;
        justify-content: space-between;
        flex-direction: row;
    }
    .qr-products-title{
        display: flex;
        width: max-content;
        justify-content: center;
        align-items: center;
    }
    .qr-products-title h5{
        font-size: 0.8rem;
    }
    .qr-products-actions{
        display: flex;
        width: max-content;
        align-items: center;
    }
    .qr-products-common{
        display: grid;
        width:max-content;
        height: max-content;
        padding: 1rem;
        align-items: center;
    }
    .qr-products-action-button-common{
        display: grid;
        width:max-content;
        height: max-content;
        color: aliceblue;
        padding: 0.3rem;
        background-color: black;
        border-radius: 5px;
        align-items: center;
        cursor: pointer;
        justify-content: center;
        box-shadow: 0px 1px 3px 2px rgb(141 140 140);
        font-size: 0.7rem;
        
        
    }
    .qr-products-action-button-common:hover{
        background-color: linear-gradient(to right, #000000 , #29282a, #343437, #434346, #525356, #626366, #7b7b7e) !important;
    }
    .qr-product-detail-info{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        /* padding: 0 2%; */
    }
    .qr-product-detail-title{
        display: flex;
    align-items: center;
    font-weight: 700;
    font-family: 'HK Grotesk', sans-serif;
    font-size: 1rem;
    margin-bottom: 0.2rem;
    
    
    }
    .qr-product-detail-code{
        display: flex;
        align-items: center;
        font-weight: 500;
        font-family: 'HK Grotesk', sans-serif;
        font-size: 0.8rem;
        margin-bottom: 0.2rem;
    
    }
    .qr-product-detail-actions{
        display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    justify-content: space-between;
    margin-bottom: 0.2rem;
        
    
    }
    .qr-product-detail-action-button-common{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: #000000;
        color: white;
        padding: 0.3rem;
        font-weight: 500;
        font-family: 'HK Grotesk', sans-serif;
        font-size: 0.7rem;
        border-radius: 5px;
        margin-right: 0.3rem;
        cursor: pointer;
        width: 50%;
        
    
    
    }
    .qr-product-detail-image{
        display: flex;
    width: 10rem;
    height: 8rem; 
    justify-content: flex-start;
    }
    .qr-product-detail-common-content{
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        justify-content: flex-start;
        
        
    
    }
    .qr-product-detail-common-content-wrapper{
        display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow-y: auto;
    
    
    }
    .qr-product-detail-common-overall{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        overflow-y: hidden;
       
    
    }
    .qr-product-detail-full{
          display: grid;
          grid-template-rows: 20% 80%;
          width: 100%;
          height: 100%;
    }
    
    .qr-product-detail-nav{
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 1rem;
        align-items: center;
        
    }
    .qr-product-detail-header{
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        font-family: 'HK Grotesk', sans-serif;
        font-size: 1.2rem;
    }
    .qr-common-icon{
        display: flex;
        flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.3rem;
    background-color: #000000;
    color: white;
    width: max-content;
    cursor: pointer;
        
        
    }
    .qr-common-icon span{
        font-size:xx-small;
    }
    .qr-common-icon:hover{
        transform: scale(0.9);
        transition: all .3s ease-in-out;
        border-radius: 10px;
        background-color: none;
    
    }

    .qr-common-icon-activate{
        display: flex;
        flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.4rem;
    background-color: #000000;
    color: white;
    width: 100%;
    cursor: pointer;
        transform: scale(1.1);
        border: 1px solid white;
        border-radius: 10px;
    
    }
    .qr-common-icon-activate span{
        font-size:x-small;

    }
   
    .qr-product-detail-common-tab-single{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 0.4rem;
        width: 18%;
        height: max-content;
        box-shadow: 0px 5px 10px 1px rgb(99, 99, 99);
        background-color: #000000;
    }
    .qr-product-detail-common-right-end{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
        width: 4.5rem;
        
    }
    .qr-product-detail-description{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-weight: 500;
        font-family: 'HK Grotesk', sans-serif;
        font-size: 0.8rem;
    
    }
    .qr-product-image-overall{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 400px;
        
    }

    .qr-product-image-center{
        display: flex;
        width: 300px;
        height: 300px;
        justify-content: center;
    }
    .qr-product-image-center img{
        width: 300px;
        height:300px;
    }
    .qr-product-image-common-button{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        border-radius: 50%;
        margin-left: 1px;
        margin-right: 1px;
        height: 50px;
        box-shadow: 0px 3px 7px 3px rgb(99, 99, 99);
        cursor: pointer;
        background-color: #000000;
        color: white;
    }
    
    .qr-product-video-overall{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 400px;
    
    }
    
    .qr-product-video-common-button{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        border-radius: 50%;
        margin-left: 0.1rem;
        margin-right: 0.1rem;
        height: 50px;
        box-shadow: 0px 3px 7px 3px rgb(99, 99, 99);
        cursor: pointer;
        background-color: #000000;
        color: white;
    
    }
    .qr-product-video-center{
        display: flex;
        width: 300px;
        height: 300px;
        /* height: 25rem; */
        justify-content: center;
    
    }
    /* .qr-product-detail-common-content-overall{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        justify-content: center;
    }
    .qr-product-detail-common-content-title{
        display: flex;
        align-items: center;
        font-weight: 700;
        font-family: 'HK Grotesk', sans-serif;
        font-size: 1.5rem;
    
    } */
    
    .qr-document-table-overall{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 20rem;
        height:20rem;
    }
    .qr-document-contentlist{
        display: flex;
        flex-direction: column;
        width:100%;
        height:100%;
        overflow-y: auto;
        -ms-overflow-style: none;  
        scrollbar-width: none;
    }
    .qr-document-contentlist::-webkit-scrollbar{
        display: none;
    }
    .qr-document-common{
        display: flex;
        flex-direction: row;
        width: 100%;
        height: auto;
        margin-bottom: 1rem;
        
    
    }
    .qr-document-title{
        display: flex;
        align-items: center;
        justify-content: left;
        width: 33%;
        margin-right: 0.5rem;
    
    }
    .document-title{
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-family: 'HK Grotesk', sans-serif;
        font-size: 0.8rem;
    
    }
    .qr-document-description{
        display: flex;
        align-items: center;
        justify-content: left;
        width: 33%;
        margin-right: 0.5rem;
    
    
    }
    .document-description{
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-family: 'HK Grotesk', sans-serif;
        font-size: 0.7rem;
    
    }
    .qr-document-action{
        display: flex;
        align-items: center;
        justify-content: right;
        width: 33%;
    
    }
    .qr-document-download-btn{
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #000000;
        width: 2rem;
        height:2rem;
        color: white;
        cursor:pointer;
    
    }
    .qr-model-view-overall{
        display: flex;
        flex-direction: column;
        /* align-items: center; */
        justify-content: flex-start;
        width: 100%;
        height: 300px;
       
    
    }
    .qr-model-view-switch-overall{
            display: flex;
            flex-direction: row;
            background-color: black;
            width: 100%;
            height: 15%;

    
    
    }
    .qr-model-view-preview-overall{
        display: flex;
        flex-direction: row;
        width: 100%;
        height:85%;
        
      
    
    }
    .qr-model-view-option-container{
        display:grid;
        
        align-items: center;
        height:100%; 
        
        border-radius: 10px;
        grid-template-columns: auto auto ;
    }
    .qr-model-view-switch-buttondiv{
        align-items:center;
      justify-content:center;
      position: inherit;
      display:grid;
      grid-template-columns: auto auto;
      grid-column-gap: 10px;
      padding: 5px;
      cursor:pointer; 
    }
    .qr-model-view-toggle-wrapper{
        position: relative;
        
        display: grid;
        width: 50px;
        height: 25px;
        justify-content: center;
        justify-content: center;
        margin-bottom: 0;
        
    }
    .qr-model-view-toggle-wrapper input{
        opacity: 0;
        width: 0;
        height: 0;
    }
    .qr-model-view-toggle-slider{
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: grey;
        transition: 0.4s;
        border-radius: 20px;
    }
    .qr-model-view-toggle-slider::before{
        content: "";
        position: absolute;
        background-color: white;
        width: 15px;
        height: 15px;
        left: 5px;
        bottom: 5px;
        transition: 0.4s;
        border-radius: 50%;
    }
    
    .qr-model-view-toggle-wrapper input:checked + .qr-model-view-toggle-slider::before{
        transform: translateX(25px) 
      }
      .qr-model-view-toggle-wrapper input:checked + .qr-model-view-toggle-slider{
        background-color: rgb(137 112 255); 
      }


}
@media only screen and (max-width: 425px){
    .qr-products-overall{
        display: grid;
        grid-template-rows: 10% 90%;
        width: 100%;
        height: 100%; 
    }
    .qr-products-header{
        display: grid;
        width: 100%;
        /* height: 80%; */
        font-weight: 700;
        margin:0;
        font-family: 'HK Grotesk', sans-serif;
        font-size: 1.2rem;
        align-items: center;
        justify-content: center;
    }
    .qr-products-list-content{
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow-y:auto;
        -ms-overflow-style: none;  
        scrollbar-width: none;
        justify-content: flex-start;
    }
    .qr-products-list-content::-webkit-scrollbar{
        display: none;
    }
    
    .qr-products-single-common{
        display: flex;
        width: 100%;
        /* height: 80px; */
        
        justify-content: space-between;
        flex-direction: row;
    }
    .qr-products-title{
        display: flex;
        width: max-content;
        justify-content: center;
        align-items: center;
    }
    .qr-products-title h5{
        font-size: 0.8rem;
    }
    .qr-products-actions{
        display: flex;
        width: max-content;
        align-items: center;
    }
    .qr-products-common{
        display: grid;
        width:max-content;
        height: max-content;
       
        align-items: center;
    }
    .qr-products-action-button-common{
        display: grid;
        width:max-content;
        height: max-content;
        color: aliceblue;
        
        background-color: black;
        border-radius: 5px;
        align-items: center;
        cursor: pointer;
        justify-content: center;
        box-shadow: 0px 1px 3px 2px rgb(141 140 140);
        font-size: 0.8rem;
        
        
    }
    .qr-products-action-button-common:hover{
        background-color: linear-gradient(to right, #000000 , #29282a, #343437, #434346, #525356, #626366, #7b7b7e) !important;
    }
    .qr-product-detail-info{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 80%;
    }
    .qr-product-detail-title{
        display: flex;
        align-items: center;
        font-weight: 700;
        font-family: 'HK Grotesk', sans-serif;
        font-size: 0.9rem;
    
    
    }
    .qr-product-detail-code{
        display: flex;
        align-items: center;
        font-weight: 500;
        font-family: 'HK Grotesk', sans-serif;
        font-size: 1rem;
    
    }
    .qr-product-detail-actions{
        display: flex;
    flex-direction: column;
    width: auto;
    height: auto;
    justify-content: space-between;
        
    
    }
    .qr-product-detail-action-button-common{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: #000000;
        color: white;
        
        font-weight: 500;
        font-family: 'HK Grotesk', sans-serif;
        font-size: 0.8rem;
        border-radius: 5px;
        cursor: pointer;
        
    
    
    }
    .qr-product-detail-image {
        display: flex;
        
        height: 10rem;
        
        
        justify-content: center;
    
    }
    .qr-product-detail-image img{
        width: 10rem;
        height: 10rem;
    }
    .qr-product-detail-common-content{
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        align-items: center;
        /* margin-top: 20px; */
    
    }
    .qr-product-detail-common-content-wrapper{
        display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    
    }
    .qr-product-detail-common-overall{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;

        justify-content: space-between;
       
    
    }
    .qr-product-detail-full{
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;
          margin:0
    }
    
    .qr-product-detail-nav{
        display: flex;
        justify-content: space-between;
        width: 100%;
        /* margin-top: 30px; */
        align-items: center;
    }
    .qr-product-detail-header{
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        font-family: 'HK Grotesk', sans-serif;
        font-size: 0.8rem;
    }
    .qr-common-icon{
        display: flex;
        align-items: center;
        justify-content: center;
       
        background-color: #000000;
        color: white;
        width: 2.5rem;
        cursor: pointer;
        
        
    }
    .qr-common-icon span{
        font-size: x-small;
    }
    .qr-common-icon:hover{
        transform: scale(0.9);
        transition: all .3s ease-in-out;
        border-radius: 10px;
        background-color: none;
    
    }
    
    .qr-common-icon-activate{
        display: flex;
        flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.4rem;
    background-color: #000000;
    color: white;
    width: auto;
    cursor: pointer;
        transform: scale(1.1);
        border: 1px solid white;
        border-radius: 10px;
    
    }
    .qr-common-icon-activate span{
        font-size:small;

    }
    .qr-product-detail-common-tab-single{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        height: auto;
        width: 100%;
        margin: 0;
        box-shadow: 0px 5px 10px 1px rgb(99, 99, 99);
        background-color: #000000;
    }
    .qr-product-detail-common-right-end{
        display: none;
        flex-direction: column;
        align-items: center;
        height: 20rem;
        width: 4.5rem;
        
    }
    .qr-product-detail-description{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-weight: 500;
        font-family: 'HK Grotesk', sans-serif;
        font-size: 0.9rem;
    
    }
    .qr-product-image-overall{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 300px;
        margin-top: 20px;
        
    }
    .qr-product-image-center{
        display: flex;
        width: 200px;
        height: 200px;
        justify-content: center;
    }
    .qr-product-image-center img{
        width: 200px;
        height: 200px;
    }
    .qr-product-image-common-button{
        display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    border-radius: 50%;
    margin-left: 1px;
    margin-right: 1px;
    height: 40px;
    box-shadow: 0px 1px 3px 1px rgb(99 99 99);
    cursor: pointer;
    background-color: #000000;
    color: white;
    }
    
    .qr-product-video-overall{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 300px;
        margin-top: 20px;
    
    }
    
    .qr-product-video-common-button{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        border-radius: 50%;
        margin-left: 0.1rem;
        margin-right: 0.1rem;
        height: 40px;
        box-shadow: 0px 1px 3px 1px rgb(99 99 99);
        cursor: pointer;
        background-color: #000000;
        color: white;
    
    }
    .qr-product-video-center{
        display: flex;
        width: 200px;
        /* height: rem; */
        justify-content: center;
    
    }
    /* .qr-product-detail-common-content-overall{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        justify-content: center;
    }
    .qr-product-detail-common-content-title{
        display: flex;
        align-items: center;
        font-weight: 700;
        font-family: 'HK Grotesk', sans-serif;
        font-size: 1.5rem;
    
    } */
    
    .qr-document-table-overall{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 300px;
        height: auto;
        margin-top: 20px;
    }
    .qr-document-contentlist{
        display: flex;
        flex-direction: column;
        width:100%;
        height:100%;
        overflow-y: auto;
        -ms-overflow-style: none;  
        scrollbar-width: none;
    }
    .qr-document-contentlist::-webkit-scrollbar{
        display: none;
    }
    .qr-document-common{
        display: flex;
        flex-direction: row;
        width: 100%;
        height: auto;
        margin-bottom: 1rem;
        
    
    }
    .qr-document-title{
        display: flex;
        align-items: center;
        justify-content: left;
        width: 33%;
    
    }
    .document-title{
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-family: 'HK Grotesk', sans-serif;
        font-size: 0.8rem;
    
    }
    .qr-document-description{
        display: flex;
        align-items: center;
        justify-content: left;
        width: 33%;
    
    
    }
    .document-description{
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-family: 'HK Grotesk', sans-serif;
        font-size: 0.7rem;
    
    }
    .qr-document-action{
        display: flex;
        align-items: center;
        justify-content: right;
        width: 33%;
    
    }
    .qr-document-download-btn{
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #000000;
        width: 2rem;
        height:2rem;
        color: white;
        cursor:pointer;
    
    }
    .qr-model-view-overall{
        display: flex;
        flex-direction: column;
        /* align-items: center; */
        justify-content: flex-start;
        width: 100%;
        height: 500px;
       
    
    }
    .qr-model-view-switch-overall{
            display: flex;
            flex-direction: row;
            background-color: black;
            width: 100%;
            height: 10%;

    
    
    }
    .qr-model-view-preview-overall{
        display: flex;
        flex-direction: row;
        width: 100%;
        height:85%;
        
      
    
    }
    .qr-model-view-option-container{
        display:grid;
        
        align-items: center;
        height:100%; 
        
        border-radius: 10px;
        grid-template-columns: auto auto ;
    }
    .qr-model-view-switch-buttondiv{
        align-items:center;
      justify-content:center;
      position: inherit;
      display:grid;
      grid-template-columns: auto auto;
      grid-column-gap: 10px;
      
      cursor:pointer; 
    }
    .qr-model-view-toggle-wrapper{
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        justify-content: center;
        margin-bottom: 0;
        
    }
    .qr-model-view-toggle-wrapper input{
        opacity: 0;
        width: 0;
        height: 0;
    }
    .qr-model-view-toggle-slider{
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: grey;
        transition: 0.4s;
        border-radius: 20px;
    }
    .qr-model-view-toggle-slider::before{
        content: "";
        position: absolute;
        background-color: white;
        width: 15px;
        height: 15px;
        left: 5px;
        bottom: 5px;
        transition: 0.4s;
        border-radius: 50%;
    }
    
    .qr-model-view-toggle-wrapper input:checked + .qr-model-view-toggle-slider::before{
        transform: translateX(25px) 
      }
      .qr-model-view-toggle-wrapper input:checked + .qr-model-view-toggle-slider{
        background-color: rgb(137 112 255); 
      }
}

@media  only screen and (max-width: 320px){
    .qr-products-overall{
        display: grid;
        grid-template-rows: 10% 90%;
        width: 100%;
        height: 100%; 
    }
    .qr-products-header{
        display: grid;
        width: 100%;
        /* height: 40%; */
        margin:0;
        font-weight: 700;
        font-family: 'HK Grotesk', sans-serif;
        font-size: 1.2rem;
        align-items: center;
        justify-content: center;
    }
    .qr-products-list-content{
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow-y:auto;
        -ms-overflow-style: none;  
        scrollbar-width: none;
        justify-content: flex-start;
    }
    .qr-products-list-content::-webkit-scrollbar{
        display: none;
    }
    
    .qr-products-single-common{
        display: flex;
        width: 100%;
        /* height: 80px; */
        
        justify-content: space-between;
        flex-direction: row;
    }
    .qr-products-title{
        display: flex;
        width: max-content;
        justify-content: center;
        align-items: center;
    }
    .qr-products-title h5{
        font-size: 0.8rem;
    }
    .qr-products-actions{
        display: flex;
        width: max-content;
        align-items: center;
    }
    .qr-products-common{
        display: grid;
        width:max-content;
        height: max-content;
       
        align-items: center;
    }
    .qr-products-action-button-common{
        display: grid;
        width:max-content;
        height: max-content;
        color: aliceblue;
        
        background-color: black;
        border-radius: 5px;
        align-items: center;
        cursor: pointer;
        justify-content: center;
        box-shadow: 0px 1px 3px 2px rgb(141 140 140);
        font-size: 0.8rem;
        
        
    }
    .qr-products-action-button-common:hover{
        background-color: linear-gradient(to right, #000000 , #29282a, #343437, #434346, #525356, #626366, #7b7b7e) !important;
    }
    .qr-product-detail-info{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 15rem;
    }
    .qr-product-detail-title{
        display: flex;
        align-items: center;
        font-weight: 500;
        font-family: 'HK Grotesk', sans-serif;
        font-size: 0.8rem;
    
    
    }
    .qr-product-detail-code{
        display: flex;
        align-items: center;
        font-weight: 500;
        font-family: 'HK Grotesk', sans-serif;
        font-size: 0.8rem;
    
    }
    .qr-product-detail-actions{
        display: flex;
    flex-direction: column;
    width: auto;
    height: auto;
    justify-content: space-between;
        
    
    }
    .qr-product-detail-action-button-common{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: #000000;
        color: white;
        
        font-weight: 500;
        font-family: 'HK Grotesk', sans-serif;
        font-size: 0.8rem;
        border-radius: 5px;
        cursor: pointer;
        
    
    
    }
    .qr-product-detail-image {
        display: flex;
        height: 10rem;
        justify-content: center;
    }
    .qr-product-detail-image img{
        width: 10rem;
        height: 10rem;
    }
    .qr-product-detail-common-content{
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        align-items: center;
    
    }
    .qr-product-detail-common-content-wrapper{
        display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    
    }
    .qr-product-detail-common-overall{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;

        justify-content: space-between;
       
    
    }
    .qr-product-detail-full{
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;
          margin:0
    }
    
    .qr-product-detail-nav{
        display: flex;
        justify-content: space-between;
        width: 100%;
        /* margin-top: 30px; */
        align-items: center;
    }
    .qr-product-detail-header{
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        font-family: 'HK Grotesk', sans-serif;
        font-size: 0.8rem;
    }
    .qr-common-icon{
        display: flex;
        align-items: center;
        justify-content: center;
       
        background-color: #000000;
        color: white;
        width: 2.5rem;
        cursor: pointer;
        
        
    }
    .qr-common-icon span{
        font-size: x-small;
    }
    .qr-common-icon:hover{
        transform: scale(0.9);
        transition: all .3s ease-in-out;
        border-radius: 10px;
        background-color: none;
    
    }
    
    .qr-common-icon-activate{
        display: flex;
        flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.4rem;
    background-color: #000000;
    color: white;
    width: auto;
    cursor: pointer;
        transform: scale(1.1);
        border: 1px solid white;
        border-radius: 10px;
    
    }
    .qr-common-icon-activate span{
        font-size:small;

    }
    .qr-product-detail-common-tab-single{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        height: auto;
        width: 100%;
        margin: 0;
        box-shadow: 0px 5px 10px 1px rgb(99, 99, 99);
        background-color: #000000;
    }
    .qr-product-detail-common-right-end{
        display: none;
        flex-direction: column;
        align-items: center;
        height: 20rem;
        width: 4.5rem;
        
    }
    .qr-product-detail-description{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        font-family: 'HK Grotesk', sans-serif;
        font-size: 0.7rem;
    
    }
    .qr-product-image-overall{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 300px;
        
    }
    .qr-product-image-center{
        display: flex;
        width: 200px;
        height: 200px;
        justify-content: center;
    }
    .qr-product-image-center img{
        width: 200px;
        height: 200px;
    }
    .qr-product-image-common-button{
        display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    border-radius: 50%;
    margin-left: 1px;
    margin-right: 1px;
    height: 40px;
    box-shadow: 0px 1px 3px 1px rgb(99 99 99);
    cursor: pointer;
    background-color: #000000;
    color: white;
    }
    
    .qr-product-video-overall{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 300px;
    
    }
    
    .qr-product-video-common-button{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        border-radius: 50%;
        margin-left: 0.1rem;
        margin-right: 0.1rem;
        height: 40px;
        box-shadow: 0px 1px 3px 1px rgb(99 99 99);
        cursor: pointer;
        background-color: #000000;
        color: white;
    
    }
    .qr-product-video-center{
        display: flex;
        width: 200px;
        /* height: rem; */
        justify-content: center;
    
    }
    /* .qr-product-detail-common-content-overall{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        justify-content: center;
    }
    .qr-product-detail-common-content-title{
        display: flex;
        align-items: center;
        font-weight: 700;
        font-family: 'HK Grotesk', sans-serif;
        font-size: 1.5rem;
    
    } */
    
    .qr-document-table-overall{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 15rem;
        height:20rem;
    }
    .qr-document-contentlist{
        display: flex;
        flex-direction: column;
        width:100%;
        height:100%;
        overflow-y: auto;
        -ms-overflow-style: none;  
        scrollbar-width: none;
    }
    .qr-document-contentlist::-webkit-scrollbar{
        display: none;
    }
    .qr-document-common{
        display: flex;
        flex-direction: row;
        width: 100%;
        height: auto;
        margin-bottom: 1rem;
        
    
    }
    .qr-document-title{
        display: flex;
        align-items: center;
        justify-content: left;
        width: 33%;
    
    }
    .document-title{
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-family: 'HK Grotesk', sans-serif;
        font-size: 0.8rem;
    
    }
    .qr-document-description{
        display: flex;
        align-items: center;
        justify-content: left;
        width: 33%;
    
    
    }
    .document-description{
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-family: 'HK Grotesk', sans-serif;
        font-size: 0.7rem;
    
    }
    .qr-document-action{
        display: flex;
        align-items: center;
        justify-content: right;
        width: 33%;
    
    }
    .qr-document-download-btn{
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #000000;
        width: 2rem;
        height:2rem;
        color: white;
        cursor:pointer;
    
    }
    .qr-model-view-overall{
        display: flex;
        flex-direction: column;
        /* align-items: center; */
        justify-content: flex-start;
        width: 100%;
        height: 500px;
       
    
    }
    .qr-model-view-switch-overall{
            display: flex;
            flex-direction: row;
            background-color: black;
            width: 100%;
            height: 10%;

    
    
    }
    .qr-model-view-preview-overall{
        display: flex;
        flex-direction: row;
        width: 100%;
        height:85%;
        
      
    
    }
    .qr-model-view-option-container{
        display:grid;
        
        align-items: center;
        height:100%; 
        
        border-radius: 10px;
        grid-template-columns: auto auto ;
    }
    .qr-model-view-switch-buttondiv{
        align-items:center;
      justify-content:center;
      position: inherit;
      display:grid;
      grid-template-columns: auto auto;
      grid-column-gap: 10px;
      
      cursor:pointer; 
    }
    .qr-model-view-toggle-wrapper{
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        justify-content: center;
        margin-bottom: 0;
        
    }
    .qr-model-view-toggle-wrapper input{
        opacity: 0;
        width: 0;
        height: 0;
    }
    .qr-model-view-toggle-slider{
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: grey;
        transition: 0.4s;
        border-radius: 20px;
    }
    .qr-model-view-toggle-slider::before{
        content: "";
        position: absolute;
        background-color: white;
        width: 15px;
        height: 15px;
        left: 5px;
        bottom: 5px;
        transition: 0.4s;
        border-radius: 50%;
    }
    
    .qr-model-view-toggle-wrapper input:checked + .qr-model-view-toggle-slider::before{
        transform: translateX(25px) 
      }
      .qr-model-view-toggle-wrapper input:checked + .qr-model-view-toggle-slider{
        background-color: rgb(137 112 255); 
      }

}

