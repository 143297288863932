/*styles.css*/
* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.helper {
  --reactour-accent: #5cb7b7;
  line-height: 1.3;
  color: #2d2323;
  width: "10px";
}

#canvas {
  height: 100vh;
  width: 100vw;
}
@keyframes spin {
  from {transform:rotate(0deg);}
  to {transform:rotateY(360deg);}
}

/* @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: portrait) {

  
  html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    height: 100vw;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
  #canvas{
    height:100vw;
    width: 100vh
  }
} */


