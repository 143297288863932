.booth-menu-container-userList {
    position: absolute;
    z-index: 999;
    width: min-content;
    display: flex;
    background: rgb(71, 36, 218);
    padding: 3px;
    flex-direction: column;
    justify-content: flex-start;
    height: 100vh;
    padding-top: 8rem;
}

/* .booth-menu-container{
    position: absolute;
    z-index: 999;
    bottom: 2rem;
    width: min-content;
    display: flex;
    background: #0e0e0e;
    padding: .5rem;
    border-radius: 5px;
    left: 50%;
    transform: translateX(-50%);
    cursor: 'pointer';
} */
/* global css */
.productDescription {
    word-break: break-all;
}

/* .boothIcons{
    margin: 0px 1px;
     padding: 1px;
     cursor: pointer;
} */
.boothDocument{
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width:55ch;
}
.documentTbody td, .documentTbody th{
    vertical-align: middle;
}
.boothDocumentDownloadButton{
    font-size: 15px;;
}
.brandLogo1{
    width:12.4%;
}
@media only screen and (max-width:1400px) {
    .brandLogo1{
        width:5.9%;
    }
}
@media only screen and (max-width:1024px) {
    .brandLogo1{
        width:7.9%;
    }
   
}
@media only screen and (max-width:825px) {
    .brandLogo1 {
        width: 8.9%;
    }
    .booth-menu-container-userList{
        padding-top: 2.5rem;
        height: auto;
    }
    .booth-menu-container-userList div img{
        width: 66px;
        height: 49px;
      }
      .booth-menu-container-userList div{
        padding: 0px !important;
        height:50px;
    }
    
}
@media only screen and (max-width:767px) {
    .booth-menu-container {
        position: absolute;
        z-index: 999;
        bottom: 2rem;
        width: 70% !important;
        display: flex;
        background: #0e0e0e;
        padding: .5rem;
        border-radius: 5px;
        left: 50%;
        transform: translateX(-50%);
        cursor: 'pointer';
    }
    /* .booth-menu-container div img{
      display: block !important;
      width: 84% !important;
      height: auto !important;
    } */
}
@media only screen and (min-width:768px) and (max-width:1023px) {
    .boothDocument{
        font-size: 14px;
    }
    .businessCardIcons button{
        margin-right:10px;
    }
    .booth-menu-container {
        position: absolute;
        z-index: 999;
        bottom: 2rem;
        width: 60% !important;
        display: flex;
        background: #0e0e0e;
        padding: .5rem;
        border-radius: 5px;
        left: 50%;
        transform: translateX(-50%);
        cursor: 'pointer';
  }
  /* .booth-menu-container div img{
    display: block !important;
    width: 84% !important;
    height: auto !important;
  }
   */
  .makeStyles-menuIconButtonContainer-10{
    top: 15%;
    right: 0.5% !important;
    padding: 3px;
    z-index: 9999;
    position: fixed;
    background-image: linear-gradient(
   90deg,#f66464,#0632ff 60%,#4eb589);
  }
  .businessCardImage{
      width: 160px !important;
      height: 186px !important;
      
  }
  .businessCardContainer{
      width: 383px !important;
      height: 186px;
  }
  .booth-menu-container-userList{
    padding-top: 2.3rem;
    height: auto;
}
.booth-menu-container-userList div{
    padding: 0px !important;
    height:49px;
}
.booth-menu-container-userList div img{
  width: 48px;
  height: 48px;
}
}

@media only screen and (max-width:650px) {
    .brandLogo1 {
        width:12.4%;
    }
   
    .videoDisplay div .videoDisplay div{
        width: 171px !important;
        height: 106px !important;
    }
    .videoDisplay div video,.videoDisplay div iframe{
        width: 171px !important;
        height: 96px !important;
    }
    .boothDocumentDownloadButton{
        padding-left:2px !important;
        padding-right: 2px !important;
    }
   
}

/* .booth-menu-container div img{
    display: block;
    height: auto;
  } */


/*start*/
@media only screen and (max-width:2560px){
    .booth-menu-container{
    position: absolute;
    z-index: 999;
    bottom: 2rem;
    width: min-content;
    display: flex;
    background: #0e0e0e;
    padding: .5rem;
    border-radius: 5px;
    left: 50%;
    transform: translateX(-50%);
    cursor: 'pointer';
    justify-content: center;

    }
    .booth-menu-icon-container{
        display: flex;
        width: 80px;
        align-items: center;
        justify-content: center;

    }
    .boothIcons{
        display: flex;
        cursor: pointer; 
        margin: 5px;
        width: 50px;
        align-items: center;
        justify-content: center;
   }
   #info{
       width: 20px;
       height: 35px;

   }
   #businesscard{
       width: 55px;
       height: 35px;

   }
   #appointment{
       width: 50px;
       height: 35px;

   }
   #videos{
       width: 50px;
       height: 37px;

   }
   #conference{
       width: 50px;
       height: 35px;

   }
   #documents{
       width: 45px;
       height: 35px;

   }
   #products{
       width: 35px;
       height: 35px;

   }
   #jobs{
       width: 20px;
       height: 35px;

   }
   #courses{
       width: 35px;
       height: 35px;

   }
   #contactus{
       width: 43px;
       height: 35px;

   }
   #textchat{
       width: 60px;
       height: 36px;

   }
   #videochat{
       width: 45px;
       height: 35px;

   }
   #share{
       width: 50px;
       height: 36px;
       
   }
   
    
  
    
   

}
@media only screen and (max-width:1440px){
    .booth-menu-container{
        position: absolute;
    z-index: 999;
    bottom: 2rem;
    width: min-content;
    display: flex;
    background: #0e0e0e;
    padding: .5rem;
    border-radius: 5px;
    left: 50%;
    transform: translateX(-50%);
    cursor: 'pointer';
    justify-content: center;

    }
    .booth-menu-icon-container{
        display: flex;
        width: 80px;
        align-items: center;
        justify-content: center;
        margin-left: 2px;
        margin-right: 2px;
        
    }
     .boothIcons{
         display: flex;
         cursor: pointer; 
         margin: 5px;
         width: 50px;
         align-items: center;
         justify-content: center;
    }
    #info{
        width: 16px;
        height: 35px;

    }
    #businesscard{
        width: 55px;
        height: 35px;

    }
    #appointment{
        width: 50px;
        height: 35px;

    }
    #videos{
        width: 50px;
        height: 39px;

    }
    #conference{
        width: 50px;
        height: 35px;

    }
    #documents{
        width: 45px;
        height: 35px;

    }
    #products{
        width: 35px;
        height: 35px;

    }
    #jobs{
        width: 20px;
        height: 35px;

    }
    #courses{
        width: 35px;
        height: 35px;

    }
    #contactus{
        width: 43px;
        height: 35px;

    }
    #textchat{
        width: 60px;
        height: 36px;

    }
    #videochat{
        width: 45px;
        height: 35px;

    }
    #share{
        width: 50px;
        height: 36px;
        
    }
    
    
    
    

}
@media only screen and (max-width:1024px){
    .booth-menu-container{
        position: absolute;
    z-index: 999;
    bottom: 2rem;
    width: min-content;
    display: flex;
    background: #0e0e0e;
    padding: .5rem;
    border-radius: 5px;
    left: 50%;
    transform: translateX(-50%);
    cursor: 'pointer';
    justify-content: center;

    }
    
    .boothIcons{
        display: flex;
        cursor: pointer; 
        margin: 5px;
        min-width: 28px;
        align-items: center;
        justify-content: center;
   }
   #info{
       width: 20px;
       height: 30px;

   }
   #businesscard{
       width: 35px;
       height: 30px;

   }
   #appointment{
       width: 35px;
       height: 35px;

   }
   #videos{
       width: 35px;
       height: 30px;

   }
   #conference{
       width: 40px;
       height: 47px;

   }
   #documents{
       width: 35px;
       height: 30px;

   }
   #products{
       width: 35px;
       height: 30px;

   }
   #jobs{
       width: 20px;
       height: 30px;

   }
   #courses{
       width: 35px;
       height: 30px;

   }
   #contactus{
       width: 35px;
       height: 30px;
   }
   #textchat{
       width: 35px;
       height: 35px;

   }
   #videochat{
       width: 35px;
       height: 30px;

   }
   #share{
       width: 35px;
       height: 30px;
       
   }
   
 


}
@media only screen and (max-width:768px){
    .booth-menu-container{
        position: absolute;
    z-index: 999;
    bottom: 2rem;
    width: min-content;
    display: flex;
    background: #0e0e0e;
    padding: .5rem;
    border-radius: 5px;
    left: 50%;
    transform: translateX(-50%);
    cursor: 'pointer';
    justify-content: center;

    }
    .boothIcons{
        display: flex;
        cursor: pointer; 
        margin: 5px;
        min-width: 26px;
        align-items: center;
        justify-content: center;
   }
   #info{
       width: 15px;
       height: 30px;

   }
   #businesscard{
       width: 35px;
       height: 30px;

   }
   #appointment{
       width: 32px;
       height: 30px;

   }
   #videos{
       width: 35px;
       height: 30px;

   }
   #conference{
       width: 35px;
       height: 40px;

   }
   #documents{
       width: 35px;
       height: 26px;

   }
   #products{
       width: 35px;
       height: 26px;

   }
   #jobs{
       width: 16px;
       height: 25px;

   }
   #courses{
       width: 30px;
       height: 25px;

   }
   #contactus{
       width: 30px;
       height: 25px;

   }
   #textchat{
       width: 40px;
       height: 29px;

   }
   #videochat{
       width: 35px;
       height: 30px;

   }
   #share{
       width: 40px;
       height: 33px;
       
   }
   

  


}
@media only screen and (max-width:425px){
    .booth-menu-container{
        position: absolute;
    z-index: 999;
    bottom: 2rem;
    width: min-content;
    display: flex;
    background: #0e0e0e;
    padding: .5rem;
    border-radius: 5px;
    left: 50%;
    transform: translateX(-50%);
    cursor: 'pointer';
    justify-content: center;

    }
    .booth-menu-icon-container{
        display: flex;
        width: 50px;
        align-items: center;
        justify-content: center;
        margin-left: 5px;
        margin-right: 5px;
        
    }
    .boothIcons{
        display: flex;
        cursor: pointer; 
        margin: 5px;
        min-width: 30px;
        align-items: center;
        justify-content: center;
   }
   #info{
       width: 15px;
       height: 25px;

   }
   #businesscard{
       width: 45px;
       height: 25px;

   }
   #appointment{
       width: 40px;
       height: 25px;

   }
   #videos{
       width: 40px;
       height: 30px;

   }
   #conference{
       width: 40px;
       height: 25px;

   }
   #documents{
       width: 35px;
       height: 25px;

   }
   #products{
       width: 25px;
       height: 25px;

   }
   #jobs{
       width: 16px;
       height: 25px;

   }
   #courses{
       width: 25px;
       height: 25px;

   }
   #contactus{
       width: 33px;
       height: 25px;

   }
   #textchat{
       width: 40px;
       height: 28px;

   }
   #videochat{
       width: 35px;
       height: 25px;

   }
   #share{
       width: 40px;
       height: 26px;
       
   }
   
  


}
@media only screen and (max-width:375px){
    .booth-menu-container{
        position: absolute;
    z-index: 999;
    bottom: 2rem;
    width: min-content;
    display: flex;
    background: #0e0e0e;
    padding: .5rem;
    border-radius: 5px;
    left: 50%;
    transform: translateX(-50%);
    cursor: 'pointer';
    justify-content: center;

    }
    .booth-menu-icon-container{
        display: flex;
        width: 50px;
        align-items: center;
        justify-content: center;
        margin-left: 5px;
        margin-right: 5px;
        
    }
    .boothIcons{
        display: flex;
        cursor: pointer; 
        margin: 5px;
        min-width: 30px;
        align-items: center;
        justify-content: center;
   }
   #info{
       width: 15px;
       height: 25px;

   }
   #businesscard{
       width: 45px;
       height: 25px;

   }
   #appointment{
       width: 40px;
       height: 25px;

   }
   #videos{
       width: 40px;
       height: 30px;

   }
   #conference{
       width: 40px;
       height: 25px;

   }
   #documents{
       width: 35px;
       height: 25px;

   }
   #products{
       width: 25px;
       height: 25px;

   }
   #jobs{
       width: 16px;
       height: 25px;

   }
   #courses{
       width: 25px;
       height: 25px;

   }
   #contactus{
       width: 33px;
       height: 25px;

   }
   #textchat{
       width: 40px;
       height: 28px;

   }
   #videochat{
       width: 35px;
       height: 25px;

   }
   #share{
       width: 40px;
       height: 26px;
       
   }
   
   
  
   
 

}
@media only screen and (max-width:320px){
    .booth-menu-container{
        position: absolute;
    z-index: 999;
    bottom: 2rem;
    width: min-content;
    display: flex;
    background: #0e0e0e;
    padding: .5rem;
    border-radius: 5px;
    left: 50%;
    transform: translateX(-50%);
    cursor: 'pointer';
    justify-content: center;

    }
    .booth-menu-icon-container{
        display: flex;
        width: 50px;
        align-items: center;
        justify-content: center;
        
    }
    .boothIcons{
        display: flex;
        cursor: pointer; 
        margin: 5px;
        min-width: 30px;
        align-items: center;
        justify-content: center;
   }
   #info{
       width: 15px;
       height: 25px;

   }
   #businesscard{
       width: 45px;
       height: 25px;

   }
   #appointment{
       width: 40px;
       height: 25px;

   }
   #videos{
       width: 40px;
       height: 30px;

   }
   #conference{
       width: 40px;
       height: 25px;

   }
   #documents{
       width: 35px;
       height: 25px;

   }
   #products{
       width: 25px;
       height: 25px;

   }
   #jobs{
       width: 16px;
       height: 25px;

   }
   #courses{
       width: 25px;
       height: 25px;

   }
   #contactus{
       width: 33px;
       height: 25px;

   }
   #textchat{
       width: 40px;
       height: 28px;

   }
   #videochat{
       width: 35px;
       height: 25px;

   }
   #share{
       width: 40px;
       height: 26px;
       
   }
   

  


}






