.menu_container{
    z-index:  10002;
    width: 100%;
    height: 100vh;
    position: absolute;
}

.menu_right_container{
    position: absolute;
    right: 0;
    width: 25%; 
    height: 100%;
}
.menu_left_container{
    height: 100%;
    width: 75%;
    background-color : rgba(0, 0, 0, .3);
    position: fixed;
}

.menu_inner_container{  
    background-color:#EBEBEB;
    height: calc(100% - 50px);    
    display: flex;
    flex-direction: column;
    margin-top: 2.5rem;
}

.top_container{
    display: flex;
    justify-content: space-around;
    background-color: white;
    border-bottom: 1px solid #EBEBEB;
}

.menu_buttons{
    display: flex;
    align-items: center;
    padding: 15px;
    cursor: pointer;
}

.menu_icons{
    font-size: 1.8rem !important;
    cursor: pointer;
    margin-right: 1rem;
}

.option_title{
    font-weight: bold;
}

.bottom_container{
    display: flex;
    flex-direction: column;
    padding: 25px;
    height: 100%;
    overflow: hidden;
}

.menuclose{
    background-image: linear-gradient(90deg,#f66464,#0632ff 60%,#4eb589) !important;
    padding: 3px !important;
    height: 45 !important;
    width: 45 !important;
   cursor: pointer !important;
   z-index: 1001 !important;
   position: absolute;
   top: 13px;
   right: 22px;
   opacity: 1 !important;
   float: right;

}

@media  screen and (max-width: 1280px )  and (min-width: 1000px){
    .menu_right_container{
        position: absolute;
        right: 0;
        width: 50%; 
    }

    .menu_left_container{
        height: 100vh;
        width: 50%;
        background-color : rgba(0, 0, 0, .3);
        position: fixed;
    }
}


@media  screen and (max-width: 1000px )  and (min-width: 700px){
    .menu_right_container{
        position: absolute;
        right: 0;
        width: 60%; 
    }

    .menu_left_container{
        height: 100vh;
        width: 40%;
        background-color : rgba(0, 0, 0, .3);
        position: fixed;
    }
}



@media  screen and (max-width: 700px)  and (min-width: 600px){
    .menu_right_container{
        position: absolute;
        right: 0;
        width: 90%; 
    }
    
    .menu_left_container{
        height: 100vh;
        width: 10%;
        background-color : rgba(0, 0, 0, .3);
        position: fixed;
    }
}


@media only screen  and (min-width: 0px)  and (max-width: 600px){
    .menu_right_container{
        position: absolute;
        right: 0;   
        width: 95%; 
    }
    
    .menu_left_container{
        height: 100vh;
        width: 5%;
        background-color : rgba(0, 0, 0, .3);
        position: fixed;
    }
}